.order-card {
    + .order-card {
        border-top: 5px solid rgba(white, 0.5);
        margin-top: rem-calc(40px);
        padding-top: rem-calc(25px);
    }

    &__header {
        align-items: center;
        display: flex;
        gap: rem-calc(20px);
        margin-bottom: rem-calc(35px);
    }

    &__body {
        > *:not(:last-child) {
            border-bottom: 5px solid rgba(white, 0.5);
            margin-bottom: rem-calc(35px);
            padding-bottom: rem-calc(35px);
        }
    }

    &__cols {
        display: flex;
        flex-direction: column;
        gap: rem-calc(40px);
        justify-content: space-between;

        @include media('>=tablet') {
            flex-direction: row;
        }
    }

    &__col {
        @include media('>=tablet') {
            flex: 1 1 auto;
            max-width: 50%;
            width: 50%;
        }

        > *:first-child {
            padding-top: 0;
        }

        > *:last-child:not(.badge, .btn-primary) {
            padding-bottom: 0;
        }

        .badge {
            margin-bottom: rem-calc(20px);
        }
    }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__infos {
        @include font-small;
        display: flex;
        flex-wrap: wrap;
        list-style: none;
    }

    &__info {
        padding: 0;

        &:last-child::after {
            display: none;
        }

        &::after {
            background-color: transparent;
            border-radius: 0;
            content: "|";
            padding: rem-calc(0 8px);
            position: static;
            speak: none;
        }
    }

    &__sums {
        @include media('>=550px') {
            padding-left: rem-calc($article-item-padding-left);
        }

        @include media('>=tablet-wide') {
            padding-left: rem-calc($article-item-padding-left-desktop);
        }

        &:before {
            background-color: rgba(white, 0.5);
            content: "";
            display: block;
            height: 1px;
            margin-bottom: rem-calc(25px);
            width: 100%;
        }

        &--btn-group {
            display: flex;
            flex-wrap: wrap;
            gap: rem-calc(20px);
            justify-content: flex-end;
            margin: 0;

            @include media('>=tablet-wide') {
                align-items: baseline;
                display: grid;
                gap: 0 rem-calc(40px);
                grid-template-columns: minmax(#{rem-calc(160px)}, auto) rem-calc(375px);
                justify-content: inherit;
            }

            @include media('>=desktop') {
                grid-template-columns: minmax(#{rem-calc(269px)}, auto) rem-calc(375px);
            }

            &::before {
                margin-bottom: rem-calc(5px);

                @include media('>=tablet-wide') {
                    margin-bottom: rem-calc(25px);
                    grid-area: 1 / 1 / 2 / 3;
                }
            }
        }
    }

    &__sum {
        align-items: flex-end;
        display: flex;
        gap: rem-calc(10px);
        justify-content: space-between;
        width: 100%;
        padding-bottom: var(--checkout-sum-padding-bottom, 1.5em);

        > * {
            flex: 1 1 auto;
        }

        > *:last-child {
            min-width: rem-calc(130px);
            text-align: right;
        }
    }

    &__sum-group {
        --checkout-sum-padding-bottom: 0;
        padding-bottom: 1.5em;
    }

    &__total {
        @include font-large;
        text-transform: uppercase;
        padding-bottom: 0;
    }

    &__change {
        padding-top: rem-calc(8px);
        text-align: right;
    }

    .btn-group {
        margin-block: rem-calc(-15px 35px);
    }
}
