.intro-banner {
    &__image {
        padding-bottom: 0;
        padding-top: Clamp(#{rem-calc(15px)}, #{vw(15px, 320)}, #{rem-calc(60px)});

        img {
            display: block;
            height: auto;
            max-width: 100%;

            @include media('<tablet-wide') {
                aspect-ratio: div(300,115);
                object-fit: cover;
            }
        }
    }

    &__text {
        > p:nth-last-child(2) {
            @include media('>=tablet-wide') {
                padding-bottom: 0;
            }
        }
    }

    > *:last-child:not(.intro-banner__image) {
        margin-top: Clamp(#{rem-calc(15px)}, #{vw(15px, 320)}, #{rem-calc(30px)});
    }
}
