.icon-left {
    margin-right: em(6px, $font-size-btn);
}

.icon-right {
    margin-left: em(6px, $font-size-btn);
}

.icon-rotate-90 {
    transform: rotate(90deg);
}

.icon-rotate-180 {
    transform: rotate(180deg);
}

.icon-rotate-270 {
    transform: rotate(270deg);
}
