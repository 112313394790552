.cart-icon {
    position: relative;

    &__badge {
        align-items: center;
        aspect-ratio: 1;
        background-color: white;
        border-radius: 50%;
        bottom: 0;
        color: var(--color-primary);
        display: flex;
        font-size: rem-calc(11px);
        height: auto;
        justify-content: center;
        position: absolute;
        right: 0;
        text-align: center;
        width: 47%;
    }
}
