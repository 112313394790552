@mixin btn-left {
    @include box-shadow-focus(focus-visible);
    --transition-override: box-shadow 0.25s ease-in, left ease-in-out 0.45s, padding ease-in-out 0.45s;

    &,
    &:link,
    &:visited {
        left: 0;
    }

    @include media('>=tablet-wide') {
        &:hover:not([disabled]) {
            left: $btn-hover-indent;
            padding-inline: em(51.5px, $font-size-btn);
        }
    }
}
