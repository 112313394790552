$footer-padding: 30px;
$footer-navitem-padding-inline: 5px;
$footer-navitem-padding-block: 15px;

.footer {
    @include font-small;
    background-color: $tangaroa;

    &__top {
        padding-block: rem-calc($footer-padding);

        @include media('>=740px') {
            align-items: center;
            display: flex;
            gap: rem-calc(10px);
            justify-content: space-between;

            .btn-primary {
                @include btn-right;
            }
        }
    }

    &__back-to-top {
        @include media('>=740px') {
            margin-left: auto;
            margin-right: 0;
        }
    }

    &__bottom {
        background-color: $midnight;
        display: flex;
        flex-wrap: wrap;
        gap: rem-calc($footer-padding);
        justify-content: space-between;
        min-width: rem-calc(284px);
        padding-block: rem-calc($footer-padding);
        position: relative;
        z-index: map-get($z-index, "footer-bottom");

        &::before,
        &::after {
            background-color: inherit;
            bottom: 0;
            content: "";
            display: block;
            position: absolute;
            top: 0;
            width: 100vw;
            z-index: map-get($z-index, "footer-bottom-bg");
        }

        &::before {
            left: -50%;
        }

        &::after {
            right: -50%;
        }

        > * {
            flex: 1 1 0;
            max-width: rem-calc(383px);
            min-width: rem-calc(273px);
        }
    }

    &__social {
        align-items: center;
        display: flex;
        justify-content: space-between;

        @include media('>=tablet') {
            gap: rem-calc($footer-padding);
            justify-content: flex-start;
        }

        a {
            &,
            &:link,
            &:visited {
                transition: opacity 0.2s ease-in;
            }

            &:hover {
                opacity: 0.8;
            }
        }
    }

    &__payment {
        display: flex;
        flex-wrap: wrap;
        gap: rem-calc(8px);
        max-width: rem-calc(350px);

        > * {
            @include font-button;
            align-items: center;
            background-color: white;
            border-radius: 3px;
            color: black;
            display: flex;
            flex: 0 1 0;
            font-size: rem-calc($font-size-btn);
            height: rem-calc(36px);
            justify-content: center;
            letter-spacing: rem-calc(3px);
            min-width: rem-calc(65px);
            padding: rem-calc(3px);

            &:last-child {
                flex-basis: auto;
                min-width: rem-calc(138px);
            }
        }
    }

    &__awards {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: rem-calc(15px);

        img {
            display: block;
        }
    }

    &__navigation {
        padding-block: rem-calc($footer-padding);
    }

    &__navlist {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: rem-calc(-$footer-navitem-padding-inline (-$footer-navitem-padding-block));
        padding: 0;

        @include media('>=tablet') {
            justify-content: center;
            margin-inline: auto;
        }

        > li {
            padding: 0;

            &::before {
                display: none;
            }
        }

        a,
        button {
            &,
            &:link,
            &:visited {
                display: inline-block;
                padding: rem-calc($footer-navitem-padding-inline $footer-navitem-padding-block);
                text-decoration: none;

                @include media('>=tablet') {
                    padding-inline: rem-calc(27px);
                }
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
