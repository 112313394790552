$spinner-size: 25px;
$spinner-border-width: 3px;

.spinner {
    &:after {
        content: "";
        display: block;
        width: rem-calc($spinner-size - $spinner-border-width);
        height: rem-calc($spinner-size - $spinner-border-width);
        border-radius: 50%;
        border-width: rem-calc($spinner-border-width);
        border-style: solid;
        border-color: white transparent white transparent;
        animation: rotate360 1.2s linear infinite;
    }

    &__backdrop {
        inset: 0;
        position: fixed;
        z-index: map-get($z-index, loading);

        &::before {
            background-color: $color-backdrop;
            content: "";
            display: block;
            inset: 0;
            position: fixed;
            z-index: -1;
        }

        .spinner {
            left: 50%;
            position: fixed;
            top: 50%;
            transform: translate(-50%, -50%);

            &::after {
                margin: rem-calc(10px) auto auto;
            }
        }
    }
}
