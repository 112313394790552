$accordion-content-padding-right-desktop: 50px;

.accordion {
	scroll-margin-top: $scroll-margin-top;

	.page-header:has(.promotion-banner.is--active) + .main & {
		scroll-margin-top: $scroll-margin-top-with-promotion-banner;
	}

	&__item {
		scroll-margin-top: $scroll-margin-top;

		.page-header:has(.promotion-banner.is--active) + .main & {
			scroll-margin-top: $scroll-margin-top-with-promotion-banner;
		}

		&:not(:last-child) {
			padding-bottom: 1.35em;
		}
	}

	&__toggle {
		@include font-large;
		align-items: center;
		cursor: pointer;
		display: flex;
		list-style: none;
		margin-left: auto;
		margin-right: 0;
		min-height: rem-calc($btn-primary-min-height);
		padding: rem-calc(0 ($btn-control-width + 12px) 0 0);
		position: relative;
		user-select: none;

		@include media('>=tablet-wide') {
			padding-right: rem-calc($btn-control-width + $accordion-content-padding-right-desktop);
		}

		&::-webkit-details-marker {
			display: none;
		}

		&:focus,
		&:focus-visible {
			outline: none;
		}

		&:focus-visible {
			.accordion__arrow {
				box-shadow: 0 0 0 0 transparent, $btn-focus-shadow;
			}
		}
	}

	&__arrow {
		@include btn-right;
		justify-content: center;
		min-width: rem-calc(74px);
		position: absolute;

		&:hover {
			@include media('>=tablet-wide') {
				left: auto;
				right: $btn-hover-indent;
			}
		}

		img {
			transform: var(--acc-arrow-transform, rotate(0));
			transition: transform 0.25s ease-in;
		}

		[open] & {
			--acc-arrow-transform: rotate(180deg);
		}
	}

	&__content {
		padding-top: 1.35em;

		@include media('>=tablet-wide') {
			padding-right: rem-calc($btn-control-width + $accordion-content-padding-right-desktop);
		}

		> *:first-child {
			padding-top: 0;
		}

		> *:last-child {
			padding-bottom: 0;
		}
	}
}
