.text-center {
    text-align: center;

    *:not(.tns-slider, .alert__icon, .icon-left, .icon-right) {
        margin-inline: auto;
    }

    ul,
    ol {
        display: inline-block;
        text-align: left;
    }

    .btn-primary {
        @include btn-center;
    }

    .alert {
        justify-content: center;

        > p {
            margin-inline: 0;
        }
    }
}
