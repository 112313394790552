.teaser-list {
    &__header {
        padding-bottom: Clamp(#{rem-calc(15px)}, #{vw(15px, 320)}, #{rem-calc(30px)});
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        list-style: none;
        margin: rem-calc(-10px);
        padding: 0;

        &.is--visible {
            --teaser-item-animation-for-odd-items: fadeInFromTop 1s ease-in-out both;
            --teaser-item-animation-for-even-items: fadeInFromBottom 1s ease-in-out both;
        }
    }

    &__item {
        opacity: var(--teaser-list-item-opacity, 1);
        transition: var(--teaser-list-item-transition);
        padding: rem-calc(10px);

        &--col-2 {
            @include media('>=tablet-wide') {
                max-width: 50%;
            }
        }

        &--col-3 {
            @include media('>=tablet') {
                max-width: 50%;
            }

            @include media('>=desktop') {
                max-width: percentage(div(1,3));
            }
        }

        &--animated {
            --teaser-list-item-opacity: 0;
            --teaser-list-item-transition: opacity 0.6s ease-in;

            @include media('>=tablet-wide') {
                --teaser-list-item-opacity: 1;
                --teaser-list-item-transition: opacity 1.2s ease-in;
            }

            &.is--visible,
            .no-js & {
                --teaser-list-item-opacity: 1;
            }

            &:nth-child(odd) {
                @include media('>=tablet-wide') {
                    animation: var(--teaser-item-animation-for-odd-items);
                }
            }

            &:nth-child(even) {
                @include media('>=tablet-wide') {
                    animation: var(--teaser-item-animation-for-even-items);
                }
            }
        }
    }
}
