.menu {
	&__level1 {
		font-size: rem-calc(25px);
		letter-spacing: rem-calc(0.5px);
		margin: Clamp(#{rem-calc(75px)}, #{vw(153px, 1440)}, #{rem-calc(153px)}) 0 rem-calc(41px);
		max-width: rem-calc(1024px);
		padding: 0;
		position: relative;
		transition: margin-top $promotion-banner-transition;

		@include media('>=tablet-wide') {
			min-height: rem-calc(451px);
		}

		.page-header:has(.promotion-banner.is--active) & {
			margin: Clamp(#{rem-calc(75px)} + var(--promotion-banner-height, 135px), #{vw(153px, 1440)} + var(--promotion-banner-height, 75px), #{rem-calc(153px)} + var(--promotion-banner-height, 75px)) 0 rem-calc(41px);
		}

		> * > .menu__link {
			hyphens: auto;
			max-width: rem-calc(425px);

			// show arrow icon on hover or if submenu is expanded
			&:hover,
			&.is--expanded,
			&.is--active {
				.menu__arrow {
					opacity: 1;
					transform: translateX(0);
					transition: opacity 0.4s, transform 0.38s cubic-bezier(0.5, 0, 0, 1.25);
				}
			}

			// show submenu
			&.is--expanded {
				+ .menu__level2 {
					// only show submenu and links if navigation-layer is opened
					opacity: 1;

					@include media('<tablet-wide') {
						left: 0;
					}

					@include media('>=tablet-wide') {
						pointer-events: auto;
						visibility: visible;
					}

					.menu__link {
						--navigation-link-visibility: visible;
						opacity: 1;
						transform: translateX(0);
						transition: opacity 0.3s ease-in, padding-left 0.3s ease-in, padding-right 0.3s ease-in, transform 0.3s ease-in;
					}

					.menu__back {
						--navigation-link-visibility: visible;
						opacity: 1;
					}
				}
			}

			&.is--expanded,
			&.is--active {
				+ .menu__level2 .menu__link.is--active {
					@include media('>=tablet-wide') {
						&::before {
							width: rem-calc(30px);
						}
					}
				}
			}
		}
	}

	&__level2 {
		font-size: rem-calc(18px);
		height: 100%;
		opacity: 0;
		padding: rem-calc(0 0 22px 0);
		pointer-events: none;
		position: absolute;
		top: 0;
		z-index: map-get($z-index, "menu-level-2");

		@include media('<tablet-wide') {
			background-color: $navigation-layer-bg-color;
			left: 100vw;
			min-height: rem-calc(500px);
			transition: left 0.8s ease-in-out, opacity 0.8s ease-in-out;
			width: 100vw;
			z-index: 1;
		}

		@include media('>=tablet-wide') {
			left: rem-calc(426px);
			transition: opacity 0.8s ease-in-out;
			visibility: hidden;
		}

		&::before {
			@include media('>=tablet-wide') {
				background: rgba(white, 0.4);
				bottom: 0;
				content: "";
				left: 0;
				position: absolute;
				top: 0;
				width: 1px;
				z-index: map-get($z-index, "menu-level-2-before");
			}
		}

		.menu__link {
			--navigation-link-visibility: hidden;
			opacity: 0;
			padding: rem-calc(10px 20px 10px 20px);
			transform: translateX(-5%);

			&::before {
				@include media('>=tablet-wide') {
					background: rgba(white, 0.4);
					content: "";
					height: 1px;
					left: 0;
					position: absolute;
					top: rem-calc(21px);
					transition: width 0.1s ease-in 0.2s;
					width: 0;
				}
			}

			&:hover,
			&.is--active {
				padding-left: rem-calc(40px);
				padding-right: 0;
			}
		}

		> * {
			@include media('<tablet-wide') {
				width: $container-width;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	&__link {
		align-items: center;
		display: flex;
		gap: rem-calc(13px);
		padding-block: rem-calc(12px);
		width: 100%;

		@include media('>=desktop') {
			padding-top: rem-calc(17px);
		}
	}

	&__arrow {
		opacity: 0;
		transform: translateX(rem-calc(-15px));
	}

	&__back {
		@include font-small;
		--navigation-link-visibility: hidden;
		letter-spacing: rem-calc(3px);
		opacity: 0;
		padding: 0;

		@include media('>=tablet-wide') {
			display: none;
		}

		img {
			margin-right: rem-calc(10px);
		}

		button {
			padding: rem-calc(16px) 0;
			width: 100%;
		}
	}
}