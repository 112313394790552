.promotion-banner {
	--link-color: #{$color-primary-darker};
	align-items: center;
	background: linear-gradient(93deg, transparent -12.29%, rgba(white, 0.6) 53.52%, transparent 112.81%), linear-gradient(93deg, rgba($color-secondary, 0.80) 0.91%, rgba($color-secondary, 0.80) 99.2%), linear-gradient(white, white);
	border-radius: 0 0 40px 40px;
	color: $color-primary-darker;
	display: flex;
	flex-wrap: wrap;
	gap: rem-calc(10px) Clamp(#{rem-calc(20px)}, #{vw(50px, 1440px)}, #{rem-calc(50px)});
	justify-content: center;
	padding: Clamp(#{rem-calc(10px)}, #{vw(14px, 320px)}, #{rem-calc(14px)}) Clamp(#{rem-calc(35px)}, #{vw(35px, 320px)}, #{rem-calc(50px)}) Clamp(#{rem-calc(10px)}, #{vw(14px, 320px)}, #{rem-calc(14px)}) Clamp(#{rem-calc(30px)}, #{vw(30px, 320px)}, #{rem-calc(50px)});
	position: relative;
	text-align: center;
	visibility: hidden;

	&.is--active {
		visibility: visible;
	}

	@include media('<tablet') {
		font-size: rem-calc(16px);
		margin-inline: Min(#{rem-calc(-11px)}, #{vw(-11px, 320px)});
	}

	@include media('>=tablet-wide') {
		background: linear-gradient(93deg, transparent -4.97%, rgba(white, 0.6) 53.39%, transparent 105.97%), linear-gradient(93deg, rgba($color-secondary, 0.80) 0.91%, rgba($color-secondary, 0.80) 99.2%), linear-gradient(white, white);
	}

	&__text {
		> *:last-child {
			padding-bottom: 0;
		}
	}

	&__btn {
		@include btn-center;

		&,
		&:link,
		&:visited {
			font-weight: bold;

			@include media('<tablet') {
				font-size: 0.6em;
			}

			&::before {
				background: $color-primary-darker;
				border: none;
			}
		}
	}

	&__close {
		align-items: center;
		background: transparent;
		display: flex;
		height: rem-calc(44px);
		justify-content: center;
		padding: 0;
		position: absolute;
		width: rem-calc(44px);

		@include media('<tablet') {
			right: rem-calc(5px);
			top: rem-calc(5px);
		}

		@include media('>=tablet', '<tablet-wide') {
			right: rem-calc(15px);
		}

		@include media('>=tablet-wide') {
			right: rem-calc(30px);
		}
	}
}