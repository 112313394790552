$cookie-layer-border-width: 2px;
$cookie-layer-border-radius: 10px;
$cookie-layer-transition: opacity 1s ease-in-out, bottom 1s ease-in-out;

.cookie-layer {
    background-color: $color-primary;
    border: rem-calc($cookie-layer-border-width) solid rgba(white, 0.4);
    border-inline-width: 0;
    bottom: rem-calc(-$cookie-layer-border-width);
    padding-block: rem-calc(30px);
    position: fixed;
    transition: $cookie-layer-transition;
    width: 100%;
    z-index: map-get($z-index, "cookie-layer");

    &__inner {
        max-width: $container-width;
        margin-left: auto;
        margin-right: auto;
    }

    @include media('>=tablet') {
        border-inline-width: rem-calc($cookie-layer-border-width);
        border-radius: rem-calc($cookie-layer-border-radius) rem-calc($cookie-layer-border-radius) 0 0;
        max-width: rem-calc(480px);
        padding-inline: rem-calc(30px);
        right: Clamp(#{rem-calc(26px)}, #{vw(26px, 768)}, #{rem-calc(60px)});
    }

    &--hidden {
        bottom: -100%;
        opacity: 0;
        transition: $cookie-layer-transition, visibility 0ms ease-in-out 1s;
        visibility: hidden;
    }
}
