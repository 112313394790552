.underlined-list {
	list-style: none;
	padding: 0;
	margin: 0;

	> * {
		border-bottom: 1px solid rgba(white, 0.5);
		padding-block: Clamp(#{rem-calc(20px)}, #{vw(40px, 1440px)}, #{rem-calc(40px)});

		&:first-child {
			padding-top: Clamp(#{rem-calc(10px)}, #{vw(20px, 1440px)}, #{rem-calc(20px)});
		}

		&:last-child {
			border-bottom: none;
		}
	}
}