@mixin gradient-transition {
    &::before {
        background: $gradient-form-field-hover;
        bottom: 0;
        content: "";
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity 0.5s linear;
    }
}
