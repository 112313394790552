.quote-slider {
	.tns-outer {
		margin-bottom: Clamp(#{rem-calc(35px)}, #{vw(110px, 1440px)}, #{rem-calc(110px)});
		position: relative;
	}

	.tns-nav {
		--start-translate-x: -50%;
		--start-translate-y: 200%;
		--final-translate-x: -50%;
		text-align: center;
		left: 50%;
		opacity: 0;
		position: absolute;

		@include media('>=tablet-wide') {
			--start-translate-x: 0;
			--final-translate-x: 0;
			bottom: rem-calc(-60px);
			left: auto;
			width: calc(100% - #{rem-calc(350px)});
		}

		@include media('>=desktop-wide') {
			--start-translate-x: 0;
			--final-translate-x: 0;
			width: calc(100% - #{rem-calc(243px)});
		}

		button {
			background-color: $color-secondary;
			border-radius: 50%;
			display: inline-block;
			height: rem-calc(15px);
			margin-inline: rem-calc(10px);
			width: rem-calc(15px);
		}

		.tns-nav-active {
			background-color: white;
		}
	}

	&.is--visible {
		.tns-nav {
			animation: fadeInFromBottom 1s ease-in-out both;
		}
	}

	.tns-ovh {
		overflow: visible;
	}

	.tns-item {
		opacity: 0;
		transition: opacity 0.5s ease-in-out;
	}

	.tns-slide-active {
		opacity: 1;
		transition-delay: 0.25s;
	}
}