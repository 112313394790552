.dialog {
	--dialog-padding: #{rem-calc(20px)};
	background-color: $biscay;
	border: rem-calc(2px) solid rgba(white, 0.4);
	border-radius: rem-calc(10px);
	color: white;
	max-height: 90vh;
	max-width: $container-width;
	overflow: auto;
	padding: var(--dialog-padding);
	position: fixed;
	z-index: map-get($z-index, dialog);

	@include media('>=tablet') {
		--dialog-padding: #{rem-calc(30px)};
		max-width: rem-calc(680px);
	}

	&--wide {
		max-width: 85vw;
		width: 100%;

		@include media('>=desktop') {
			max-width: rem-calc(1200px);
		}
	}

	&::backdrop {
		background-color: $color-backdrop;
	}

	&__head,
	&__footer {
		background-color: $color-primary;
		margin-inline: calc(var(--dialog-padding) * -1);
		padding: Clamp(#{rem-calc(12px)}, vw(17px, 1440), rem-calc(17px)) rem-calc(20px) Clamp(#{rem-calc(18px)}, vw(23px, 1440), rem-calc(23px)) rem-calc(20px);
		text-align: right;
	}

	&__head {
		margin-block: calc(var(--dialog-padding) * -1) Clamp(rem-calc(20px), vw(30px, 1440), rem-calc(30px));
	}

	&__footer {
		margin-block: Clamp(rem-calc(20px), vw(30px, 1440), rem-calc(30px)) calc(var(--dialog-padding) * -1);
	}

	&__content {
		> * {
			&:first-child {
				padding-top: 0;
			}

			&:last-child {
				padding-bottom: 0;
			}
		}
	}

	&__flex {
		align-items: flex-start;
		display: flex;
		flex-wrap: wrap;
		gap: Clamp(#{rem-calc(10px)}, vw(40px, 1440), rem-calc(40px));
		justify-content: center;

		> * {
			flex: 1 1 100%;

			@include media('>=tablet') {
				flex-basis: rem-calc(254px);
			}

			> * {
				&:first-child {
					padding-top: 0;
				}

				&:last-child {
					padding-bottom: 0;
				}
			}
		}
	}

	&__flex-image {
		flex-basis: rem-calc(80px);
		flex-grow: 0;
		height: auto;
		max-width: rem-calc(80px);

		@include media('>=desktop') {
			max-width: rem-calc(110px);
		}
	}

	&__close {
		&:focus-visible {
			outline-offset: rem-calc(5px);
		}
	}

	&[open] {
		animation: scaleUpFromBottom 0.5s cubic-bezier(0.4, 0.0, 0.2, 1) forwards;
	}

	.alert {
		margin-bottom: rem-calc(20px);
	}
}
