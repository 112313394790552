.alert {
    align-items: flex-start;
    display: flex;

    &__icon {
        flex-grow: 0;
        flex-shrink: 0;
        margin-right: rem-calc(11px);
        margin-top: 0.1em;
    }

    > *:last-child {
        padding-bottom: 0;
    }

    &__text {
        > *:last-child {
            padding-bottom: 0;
        }
    }

    &.text-center {
        justify-content: center;

        .alert__icon {
            margin-left: 0;
        }

        > p {
            margin-inline: 0;
        }
    }

    &.element:not([hidden]) + .alert.element:not([hidden]) {
        margin-top: Clamp(#{rem-calc(-30px), #{vw(-10px, 320)}, #{rem-calc(-10px)}});
    }
}
