.amount-input {
    max-width: rem-calc(237px);
    position: relative;
    width: 100%;

    &__decrease,
    &__increase {
        position: absolute;
        top: 0;
        z-index: map-get($z-index, "control-button");
    }

    &__decrease {
        left: 0;
    }

    &__increase {
        @include btn-right;
    }

    &__value {
        @include font-basic;
        background: transparent;
        border: 0 none;
        color: white;
        font-size: inherit;
        min-height: rem-calc($btn-primary-min-height);
        padding: rem-calc(0 $btn-control-width);
        text-align: center;
        width: 100%;

        &:focus,
        &:focus-visible {
            outline: none;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &[type=number] {
            -moz-appearance: textfield;
        }
    }
}
