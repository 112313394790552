.order-grid {
    display: flex;
    flex-direction: column;
    gap: rem-calc(20px);
    justify-content: center;

    @include media('>=desktop') {
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__col {
        padding-bottom: rem-calc(20px);

        > *:first-child {
            padding-top: 0;
        }

        &--left {
            @include media('>=desktop') {
                margin-inline: auto;
                max-width: rem-calc(466px);
                width: rem-calc(466px);
            }
        }

        &--right {
            @include media('>=desktop') {
                flex: 1 1 auto;
                max-width: 50%;
                width: 50%;
            }
        }
    }
}

