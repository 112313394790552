.login-registration {
	display: flex;
	flex-wrap: wrap;
	gap: rem-calc(24px);
	justify-content: center;
	margin-inline: auto;
	max-width: rem-calc(1192px);

	@include media('>=tablet-wide') {
		justify-content: space-between;
	}

	> *:not(.alert) {
		flex: 1 1 auto;
		max-width: rem-calc(465px);
		width: 50%;
	}

	> .alert {
		--element-padding-top: 0;
		flex: 1 1 100%;
		max-width: 100%;
		width: 100%;
	}
}