.steplist {
    display: flex;
    list-style: none;
    justify-content: space-evenly;
    padding-block: rem-calc(4px 0);
    padding-inline: 0;

    @include media('>=phone-wide') {
        justify-content: space-between;
    }

    @include media('>=tablet-wide') {
        margin-inline: auto;
        max-width: rem-calc(985px);
        padding-top: rem-calc(20px);
    }

    &__item {
        @include font-small;
        align-items: center;
        display: flex;
        gap: Clamp(#{rem-calc(5px)}, #{vw(5px, 320px)}, #{rem-calc(13px)});

        @include media('>=tablet-wide') {
            @include font-large;
        }

        &--active {
            --steplist-marker-background: #{$purple};
        }

        &__marker {
            --steplist-marker-circle-size: #{rem-calc(25px)};
            --steplist-marker-circle-border-width: 1px;

            @include font-small;
            align-items: center;
            border: var(--steplist-marker-circle-border-width) solid white;
            border-radius: 50%;
            flex-shrink: 0;
            display: inline-grid;
            height: var(--steplist-marker-circle-size);
            justify-content: center;
            position: relative;
            width: var(--steplist-marker-circle-size);

            @include media('>=tablet-wide') {
                --steplist-marker-circle-size: #{rem-calc(35px)};
                --steplist-marker-circle-border-width: 2px;

                @include font-large;
            }

            &::before {
                --steplist-marker-active-circle-size: #{rem-calc(104px)};
                background-color: var(--steplist-marker-background, transparent);
                border-radius: 50%;
                content: '';
                display: block;
                height: var(--steplist-marker-active-circle-size);
                left: 50%;
                position: absolute;
                top: rem-calc(-18px);
                transform: translateX(-50%);
                width: var(--steplist-marker-active-circle-size);
                z-index: map-get($z-index, "steplist-marker-before");

                @include media('>=tablet-wide') {
                    top: rem-calc(-38px);
                    height: Clamp(var(--steplist-marker-active-circle-size), #{vw(216px, 1440px)}, #{rem-calc(216px)});
                    width: Clamp(var(--steplist-marker-active-circle-size), #{vw(216px, 1440px)}, #{rem-calc(216px)});
                }
            }

            > img {
                width: 100%;
                height: auto;

                @include media('>=tablet-wide') {
                    width: 0.72em;
                }
            }
        }

        &__text {
            &:not(.steplist__item--active &) {
                @include media('<phone-wide') {
                    @include visually-hidden;
                }
            }
        }
    }
}
