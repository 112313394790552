body {
    @include font-basic;
    font-size: $font-size-body;
    line-height: 1.25;
}

p,
.display-p {
    margin: 0;
    padding: 0 0 1em;
}

.display-p {
    @include font-basic;
    font-size: $font-size-body;
    text-transform: none;
}

b,
strong {
    font-weight: bold;
}

h1,
.display-h1,
h2,
.display-h2,
h3,
.display-h3,
h4,
.display-h4,
h5,
.display-h5,
h6,
.display-h6 {
    @include font-headline;
    margin: 0;
    padding-top: 0.25em;
}

h1,
.display-h1 {
    font-size: Clamp(#{rem-calc(30px)}, #{vw(60px, 1440px)}, #{rem-calc(60px)});
    line-height: 1.08333;
    padding-bottom: 0.13333em;
}

h2,
.display-h2 {
    font-size: $font-size-h2;
    line-height: 1.12;
    padding-bottom: 1em;
}

h3,
.display-h3 {
    font-size: Clamp(#{rem-calc(20px)}, #{vw(20px, 640px)}, #{rem-calc(30px)});
    line-height: 1.13333;
    padding-bottom: 1em;
    padding-top: 1em;
}

h4,
.display-h4,
h5,
.display-h5,
h6,
.display-h6 {
    font-size: Clamp(#{rem-calc(18px)}, #{vw(28px, 1440px)}, #{rem-calc(28px)});
    line-height: 1.22222;
    padding-bottom: 0.2em;
}

a {
    &,
    &:link,
    &:visited {
        color: var(--link-color, white);
        text-decoration: underline;
    }

    &:hover {
        text-decoration: none;
    }

    &:focus-visible {
        outline: 1px solid white;
    }
}

blockquote {
    font-family: Georgia, serif;
    font-size: Clamp(#{rem-calc(20px)}, #{vw(30px, 1440px)}, #{rem-calc(30px)});
    font-style: italic;
    font-weight: 700;
    letter-spacing: 0;
    margin: 0;
}

.large {
    @include font-large;
}

small,
.small {
    @include font-small;
}

ol {
    margin-block: 0;
    padding: 0 0 1em 1em;
}

ul:not(.section-menu__list, .dropdown-menu__list, .footer__navlist, .cart__items, .article__infos, .checkout-order__items,
.checkout-delivery__items, .account-navigation, .order-card__list, .masonry__grid, .teaser-list__list, .nav, .pagination,
.menu-variants, .menu-descriptions, .underlined-list) {
    list-style: none;
    margin-block: 0;
    padding: 0 0 1em;

    li {
        position: relative;

        &::before {
            content: "";
            display: inline-block;
            position: absolute;
        }
    }
}

ul:not(.section-menu__list, .dropdown-menu__list, .footer__navlist, .checklist, .cart__items, .article__infos, .checkout-order__items,
.checkout-delivery__items, .account-navigation, .order-card__list, .masonry__grid, .teaser-list__list, .nav, .pagination,
.menu-variants, .menu-descriptions, .underlined-list) {
    li {
        padding-left: em(21px, 20px);

        &::before {
            background: white;
            border-radius: 50%;
            height: em(5px, 20px);
            left: em(8px, 20px);
            top: em(10px, 20px);
            width: em(5px, 20px);
        }
    }
}

li {
    padding-bottom: 1em;
}
