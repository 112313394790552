@keyframes fadeInFromRight {
	0% {
		opacity: 0;
		transform: translateX(var(--start-translate-x, 185px)) scale(var(--start-scale, 1));
		transform-origin: var(--start-transform-origin, 50% 50%);
	}

	100% {
		opacity: 1;
		transform: translateX(var(--final-translate-x, 0)) scale(var(--final-scale, 1));
	}
}