$btn-primary-min-height: 47px;
$btn-focus-shadow: 0 0 15px 2px rgba($alto, 0.7);
$btn-control-width: 77px;
$btn-hover-indent: -2em;

$container-width-min: 16rem;
$container-width-middle: 93.25vw;
$container-width-max: 90rem;

$container-width: Clamp(#{$container-width-min}, #{$container-width-middle}, #{$container-width-max});

$section-padding-block-min: 30px;
$section-padding-block-middle: vw(90px, 1440);
$section-padding-block-max: 90px;

$text-media-aspect-ratio: div(710,946);
$text-media-max-media-width: 710px;

$teaser-image-aspect-ratio: div(3,4);
$teaser-image-width-xs: 76.875vw;
$teaser-image-max-width: 467px;

$teaser-large-image-aspect-ratio: div(4,3);
$teaser-large-image-max-width: 710px;

$scroll-margin-top: Clamp(#{rem-calc(91px)}, #{vw(141px, 1440px)}, #{rem-calc(141px)});
$scroll-margin-top-with-promotion-banner: Clamp(#{rem-calc(91px)} + var(--promotion-banner-height, 135px), #{vw(141px, 1440px)} + var(--promotion-banner-height, 75px), #{rem-calc(141px)} + var(--promotion-banner-height, 75px));

$navigation-layer-bg-color: $tangaroa;

$promotion-banner-transition: 500ms ease-in;
