.quote {
	opacity: 0;

	~ .quote:not(.tns-slider .quote) {
		display: none;

		.no-js & {
			display: block;
		}
	}

	.is--visible &,
	.no-js & {
		opacity: 1;
	}

	&__inner {
		@include media('>=tablet-wide') {
			display: flex;
			flex-wrap: wrap;
			gap: rem-calc(22px);
		}
	}

	&__content {
		display: flex;
		flex-flow: column;
		justify-content: space-between;
		padding-block: rem-calc(30px);
		position: relative;

		@include media('>=tablet-wide') {
			flex-basis: 60%;
			flex-grow: 1;
		}

		@include media('>=desktop-wide') {
			flex: 1 0 rem-calc(951px);
			padding-left: rem-calc(120px);
		}

		&::before,
		&::after {
			--start-translate-x: -50%;
			--final-translate-x: -50%;
			background-color: $color-primary;
			border-radius: 50%;
			box-sizing: border-box;
			content: "”";
			display: block;
			font-family: $font-family-header;
			font-size: Clamp(#{rem-calc(120px)}, #{vw(200px, 1440px)}, #{rem-calc(200px)});
			height: Clamp(#{rem-calc(70px)}, #{vw(70px, 320px)}, #{rem-calc(110px)});
			left: 50%;
			line-height: 1.05;
			position: absolute;
			width: Clamp(#{rem-calc(70px)}, #{vw(70px, 320px)}, #{rem-calc(110px)});
			z-index: map-get($z-index, "quote-content-before");

			@include media('>=tablet-wide') {
				background-color: transparent;
				left: calc(50% + #{rem-calc(8px)});
				padding-inline: rem-calc(20px);
			}
		}

		&::before {
			--start-translate-y: -100%;
			top: 0;

			@include media('>=desktop') {
				top: rem-calc(-20px);
			}

			@include media('>=desktop-wide') {
				--start-translate-x: calc(-50% + #{rem-calc(60px)});
				--final-translate-x: calc(-50% + #{rem-calc(60px)});
			}

			.is--visible & {
				animation: fadeInFromTop 1s ease-in-out both;
			}
		}

		&::after {
			--start-rotate: 180deg;
			--start-translate-y: 100%;
			--final-rotate: 180deg;
			bottom: rem-calc(-4px);

			@include media('>=desktop') {
				bottom: rem-calc(-27px);
			}

			@include media('>=desktop-wide') {
				--start-translate-x: calc(-50% + #{rem-calc(60px)});
				--final-translate-x: calc(-50% + #{rem-calc(60px)});
			}

			.is--visible & {
				animation: fadeInFromBottom 1s ease-in-out both;
			}
		}
	}

	&__divider {
		position: relative;
		width: 100%;
		z-index: map-get($z-index, "quote-divider");

		&:first-child {
			.is--visible & {
				--start-translate-y: -100%;
				animation: fadeInFromTop 1s ease-in-out;
				animation-fill-mode: both;
			}
		}

		&:last-child {
			.is--visible & {
				--start-translate-y: 100%;
				animation: fadeInFromBottom 1s ease-in-out;
				animation-fill-mode: both;
			}
		}
	}

	&__text {
		--start-translate-y: #{rem-calc(20px)};
		padding-block: rem-calc(45px);

		.is--visible & {
			animation: fadeInFromBottom 0.75s ease-in-out 0.5s;
			animation-fill-mode: both;
		}

		> *:last-child {
			padding-bottom: 0;
		}
	}

	&__image {
		display: none;
		flex-grow: 0;
		flex-shrink: 0;

		@include media('>=tablet-wide') {
			display: block;
			opacity: 0;
			transform: scale(0);
			transform-origin: 100% 50%;
			transition: opacity 1s ease-in-out, transform 1s ease-in-out;
		}

		.is--visible &,
		.no-js & {
			@include media('>=tablet-wide') {
				opacity: 1;
				transform: scale(1);
			}
		}

		img {
			border-radius: 50%;
			display: block;
		}
	}
}
