@keyframes scaleInFromBottom {
	0% {
		opacity: 0;
		transform: scale(0) translateY(100px);
	}
	100% {
		opacity: 1;
		transform: scale(1) translateY(0);
	}
}
