.account-navigation {
    display: grid;
    gap: Clamp(#{rem-calc(20px)}, #{vw(20px, 320)}, #{rem-calc(40px)}) rem-calc(20px);
    grid-template-columns: repeat(2, 1fr);
    list-style: none;
    margin: 0;
    padding: 0;

    @include media('>=tablet') {
        display: flex;
        justify-content: space-between;
    }

    > li {
        padding: 0;

        @include media('>=tablet') {
            flex-basis: 25%;
        }
    }
}
