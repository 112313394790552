.article {
    @include media('>=tablet') {
        display: grid;
        gap: rem-calc(0 40px);
        grid-auto-columns: minmax(#{rem-calc(269px)}, 68%) minmax(auto, #{rem-calc(235px)});
        justify-content: space-between;
    }

    @include media('>=tablet-wide') {
        grid-auto-columns: minmax(#{rem-calc(269px)}, auto) minmax(auto, #{rem-calc(330px)});
    }

    @include media('>=desktop') {
        grid-auto-columns: minmax(#{rem-calc(269px)}, auto) #{rem-calc(467px)};
    }

    &::before {
        display: none;
    }

    &--ticket,
    &--aktions-ticket {
        --article-icon-size: #{percentage(div(50, 81)) auto};
        --article-icon: url("./../../images/article-ticket-white.svg");
    }

    &--aktions-ticket {
        --article-icon-bg: #{$gradient-article-promotion-ticket};
    }

    &--coupon {
        --article-icon-size: #{percentage(div(49, 81)) auto};
        --article-icon: url("./../../images/article-coupon-white.svg");
    }

    &--fantissima,
    &--fantissima-vorverkauf {
        --article-icon-size: #{percentage(div(61, 81)) auto};
        --article-icon: url("./../../images/article-fantissima-white.svg");
    }

    &--fantissima-vorverkauf {
        --article-icon-bg: #{$gradient-article-fantissima-presale};
    }

    &--celebreat {
        --article-icon-size: #{percentage(div(30, 81)) auto};
        --article-icon: url("./../../images/article-celebreat-white.svg");
    }

    &--wine {
        --article-icon-size: #{percentage(div(50, 81)) auto};
        --article-icon: url("./../../images/article-wine-white.svg");
    }

    &--overnight-stay {
        --article-icon-size: #{percentage(div(49, 81)) auto};
        --article-icon: url("./../../images/article-overnight-stay-white.svg");
    }

    &--envelope {
        --article-icon-size: #{percentage(div(49, 81)) auto};
        --article-icon: url("./../../images/article-envelope-white.svg");
    }

    &--gift-box {
        --article-icon-size: #{percentage(div(49, 81)) auto};
        --article-icon: url("./../../images/article-gift-box-white.svg");
    }

    &--sold {
        opacity: 0.5;
        pointer-events: none;

        .amount-input {
            @include media('>=550px', '<tablet') {
                text-align: center;
            }

            @include media('>=tablet', '<desktop') {
                text-align: right;
            }

            @include media('>=desktop') {
                text-align: center;
            }
        }
    }

    &__item,
    &__total-left {
        position: relative;

        @include media('>=550px') {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 3;
            padding-bottom: 0;
        }
    }

    &__item {
        padding: rem-calc(0 0 0 $article-item-padding-left);
        position: relative;

        @include media('>=tablet-wide') {
            padding-left: rem-calc($article-item-padding-left-desktop);
        }

        @include media('>=desktop') {
            min-height: rem-calc(90px);
        }

        &::before {
            background-color: $ripe-plum;
            background-image: var(--article-icon), var(--article-icon-bg, none);
            background-position: center;
            background-repeat: no-repeat;
            background-size: var(--article-icon-size), 100%, 100%;
            border-radius: 50%;
            content: "";
            display: block;
            height: rem-calc(45px);
            left: 0;
            position: absolute;
            top: rem-calc(-8px);
            width: rem-calc(45px);

            @include media('>=tablet-wide') {
                height: rem-calc(81px);
                width: rem-calc(81px);
            }
        }

        &--long {
            @include media('>=550px') {
                grid-column-end: 3;
            }
        }
    }

    &__name {
        @include font-large;
        display: inline-block;
        font-weight: inherit;
        padding-bottom: rem-calc(8px);

        @include media('>=desktop') {
            padding-top: rem-calc(8px);
        }
    }

    &__infos {
        @include font-small;
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: rem-calc(0 0 9px 0);
    }

    &__info {
        padding: 0;

        &:last-child::after {
            display: none;
        }

        &::after {
            background-color: transparent;
            border-radius: 0;
            content: "|";
            padding: rem-calc(0 8px);
            position: static;
            speak: none;
        }

        .price-old {
            display: inline-block;
            padding-left: rem-calc(6px);
        }
    }

    &__amount,
    &__sum {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: rem-calc(10px) rem-calc(50px);
        justify-content: space-between;
        margin-left: rem-calc($article-item-padding-left);
        padding: rem-calc(0 0 21px);

        @include media('>=550px') {
            align-items: baseline;
            grid-column-start: 2;
            grid-column-end: 3;
            justify-content: flex-end;
            margin-left: 0;
        }

        @include media('>=tablet-wide') {
            gap: rem-calc(20px);
        }

        @include media('>=desktop-wide') {
            justify-content: space-between;
        }

        // additional class for field on shopping cart page
        &--in-cart {
            gap: rem-calc(10px);

            @include media('<phone-wide') {
                margin-left: 0;
            }

            @include media('>=550px') {
                justify-content: flex-start;
            }

            @include media('>=tablet-wide') {
                justify-content: space-between;
            }
        }
    }

    &__amount {
        @include media('>=550px') {
            grid-row-start: 1;
            grid-row-end: 2;
        }

        // additional class for field on shopping cart page
        &--in-cart {
            padding-top: rem-calc(21px);

            @include media('>=550px') {
                padding-top: rem-calc(4px);
            }
        }

        .price-old {
            @include font-small;
        }
    }

    &__sum {
        @include media('>=550px') {
            grid-row-start: 2;
            grid-row-end: 3;
        }
    }

    &__total {
        @include font-large;
        padding: rem-calc(12px 0 0);
        text-transform: uppercase;
    }

    &__button {
        flex: 1 1 100%;
        margin-left: auto;
        margin-right: 0;
        text-align: right;
        width: rem-calc(260px);

        .btn-primary {
            @include btn-right;
        }
    }

    &__add-to-cart {
        display: flex;
        flex-wrap: wrap;
        gap: rem-calc(20px);
        justify-content: space-between;
        margin: 0;

        @include media('>=tablet-wide') {
            align-items: baseline;
            display: grid;
            gap: 0 rem-calc(20px);
            grid-template-columns: minmax(#{rem-calc(160px)}, auto) rem-calc(160px) rem-calc(263px);
            justify-content: inherit;
        }

        @include media('>=desktop') {
            grid-template-columns: minmax(#{rem-calc(269px)}, auto) rem-calc(160px) rem-calc(288px);
        }

        @include media('>=desktop', '<desktop-wide') {
            gap: 0;
        }

        .cart__price {
            @include media('>=desktop', '<desktop-wide') {
                min-width: rem-calc(188px);
            }
        }
    }

    &__actions {
        align-items: baseline;
        display: flex;
        flex-wrap: wrap;
        gap: rem-calc(20px);

        > * {
            flex-shrink: 0;
            flex-grow: 0;
        }

        form {
            position: relative;
            top: rem-calc(-4px);
            width: rem-calc(130px);
        }
    }
}
