.section-menu {
    position: relative;

    &__btn {
        @extend .form__select;
        background: none white;

        @include media('>=tablet-wide') {
            display: none;
        }

        + .section-menu__list {
            @include media('<tablet-wide') {
                opacity: 0;
                visibility: hidden;
            }
        }

        &[aria-expanded="true"]{
            --section-menu-arrow-transform: rotate(360deg);
            border-radius: rem-calc(25px) rem-calc(25px) 0 0;

            + .section-menu__list {
                @include media('<tablet-wide') {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    &__arrow {
        @extend .form__select-arrow-box;

        > img {
            transform: var(--section-menu-arrow-transform, rotate(180deg));
            transition: transform 0.25s ease-in;
        }

        [aria-expanded="true"] & {
            border-radius: 0 rem-calc(25px) 0 0;
        }
    }

    &__list {
        list-style: none;
        margin: 0;
        padding-inline: 0;

        @include media('<tablet-wide') {
            --link-color: black;
            background-color: white;
            border-radius: 0 0 $form-field-border-radius $form-field-border-radius;
            left: $form-field-border-width;
            overflow: hidden;
            padding-block: Clamp(#{rem-calc(15px)}, #{vw(14px, 1440px)}, #{rem-calc(14px)});
            position: absolute;
            right: $form-field-border-width;
            z-index: map-get($z-index, "section-menu-list")
        }

        @include media('>=tablet-wide') {
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            padding-block: 0;
        }

        > li {
            padding: 0;

            @include media('>=tablet-wide') {
                flex-basis: percentage(div(1,7));
                max-width: percentage(div(1,7));
                padding: rem-calc(10px);
            }

            .btn-icon-link {
                @include media('<tablet-wide') {
                    align-items: baseline;
                    text-align: left;
                    padding:
                        Clamp(#{rem-calc(15px)}, #{vw(14px, 1440px)}, #{rem-calc(14px)})
                        rem-calc(18px)
                        Clamp(#{rem-calc(15px)}, #{vw(14px, 1440px)}, #{rem-calc(14px)})
                        rem-calc(18px);
                }

                > img {
                    @include media('<tablet-wide') {
                        display: none;
                    }
                }
            }
        }
    }
}
