html {
	overflow-x: hidden;
    height: 100%;
}

body {
	background: $gradient-body;
	color: var(--text-color, white);
    display: flex;
    flex-direction: column;
	margin: 0;
    min-height: 100%;
	overflow: hidden;
	padding: 0;
	position: relative;

	&::before {
		backdrop-filter: blur(20px);
		bottom: 0;
		content: "";
		display: block;
		left: 0;
		opacity: 0;
		position: absolute;
		right: 0;
		top: 0;
		transition: opacity 0.8s;
		visibility: hidden;
		z-index: map-get($z-index, "body-before")
	}

	&.has--opened-navigation-layer {
		&::before {
			opacity: 1;
			visibility: visible;
		}
	}
}
