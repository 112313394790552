.main {
	overflow: hidden;
	flex: 1 0 auto;

	.container {
		> *:first-child {
			padding-top: Clamp(#{rem-calc(91px)}, #{vw(171px, 1440px)}, #{rem-calc(171px)});
			transition: padding-top $promotion-banner-transition;

			.page-header:has(.promotion-banner.is--active) + & {
				padding-top: Clamp(#{rem-calc(91px)} + var(--promotion-banner-height, 135px), #{vw(171px, 1440px)} + var(--promotion-banner-height, 75px), #{rem-calc(171px)} + var(--promotion-banner-height, 75px));
			}
		}
	}
}
