.menu-variants {
	display: flex;
	flex-wrap: wrap;
	gap: Clamp(#{rem-calc(15px)}, vw(20px, 1440), rem-calc(20px));
	list-style: none;
	margin: 0;
	padding: rem-calc(0 0 40px);

	&__item {
		background-color: $ebony;
		background-image: url("./../../images/fantissima-menu-variant.png");
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: rem-calc(10px);
		display: flex;
		flex: 1 1 100%;
		flex-direction: column;
		justify-content: flex-start;
		max-width: rem-calc(465px);
		min-height: rem-calc(127px);
		overflow: hidden;
		padding: 0 0 rem-calc(20px);
		position: relative;

		@include media('>=550px') {
			flex: 1 1 0;
			min-height: rem-calc(270px);
		}

		@include media('>=tablet') {
			padding-bottom: rem-calc(30px);
		}

		&::before {
			background-color: rgba($ebony, 0.7);
			content: "";
			display: block;
			inset: -1px;
			transition: background-color 0.5s ease;
			position: absolute;
			z-index: 1;
		}

		&:hover {
			&::before {
				background-color: rgba($ebony, 0.1);
			}

			.menu-variants__content {
				opacity: 0;
				transform: scale(1.3);
			}
		}
	}

	&__info-button {
		align-self: flex-end;
		background-color: transparent;
		margin: 0;
		z-index: 2;

		&::before {
			content: "";
			display: block;
			inset: 0;
			position: absolute;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		position: relative;
		text-align: center;
		transform: scale(1);
		transition: opacity 0.5s ease, transform 0.75s ease;
		z-index: 1;

		* {
			display: block;
			margin-inline: rem-calc(20px);
			padding: 0;

			@include media('>=tablet') {
				margin-inline: rem-calc(40px);
			}
		}
	}

	&__variant-capital {
		@include font-headline;
		font-size: 1.125em;
	}

	&__variant-title {
		border-bottom: 1px solid rgba(white, 0.5);
		margin-block: rem-calc(-18px 18px);
		padding: rem-calc(0 0 15px);

		@include media('<tablet') {
			line-height: 1.6;
		}

		@include media('>=tablet') {
			margin-top: 0;
		}
	}

	&__headline {
		padding-top: 0.75em;
	}

	&__image {
		bottom: 0;
		height: 100%;
		left: 50%;
		position: absolute;
		top: 0;
		transform: translateX(-50%);
		width: auto;
	}
}