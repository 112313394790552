.gallery {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	width: calc(100% + ((100vw - 100%) / 2));

	.no-js & {
		width: 100%;
	}

	@include media('>=tablet-wide') {
		display: grid;
		gap: rem-calc(32px);
		grid-template-columns: minmax(200px, 1fr) minmax(700px, 1192px);

		.no-js & {
			grid-template-columns: 1fr;
		}
	}

	> *:last-child {
		grid-column: 1 / 2;
		grid-row: 1 / 1;

		.no-js & {
			grid-column: auto;
			grid-row: auto;
		}
	}

	> *:first-child {
		grid-column: 2 / 3;
		grid-row: 1 / 1;

		.no-js & {
			grid-column: auto;
			grid-row: auto;
		}
	}

	> *:first-child {
		.no-js & {
			display: grid;
			gap: rem-calc(32px);
			grid-template-columns: repeat(3, 1fr);
			grid-auto-rows: auto;
		}
	}

	> * {
		opacity: 0;
		transform: scale(1.3435) translateX(500px);
		transform-origin: 0 50%;
		transition: opacity 2s ease-in-out 0.25s, transform 1.25s ease-in-out;

		.no-js & {
			opacity: 1;
			transform: scale(1) translateX(0);
		}
	}

	&.is--visible > * {
		opacity: 1;
		transform: scale(1) translateX(0);
	}

	&__controls-container {
		align-items: center;
		display: none;
		justify-content: center;
		order: 2;
		opacity: 0;
		transform: translateX(rem-calc(-341px));
		transition: opacity 1.75s ease-in-out 1.25s, transform 1.2s ease-in-out 1.25s;

		.tns-outer + & {
			display: flex;
		}
	}

	&.is--visible .gallery__controls-container {
		opacity: 1;
		transform: translateX(0);
	}

	&__controls {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		gap: Clamp(#{rem-calc(15px)}, #{vw(34px, 1440px)}, #{rem-calc(34px)});
		justify-content: center;
		margin-bottom: rem-calc(20px);
		margin-top: rem-calc(30px);
	}

	&__item {
		transition: transform 0.3s ease-in-out;

		&:hover {
			transform: scale(1.03) translateX(0);
		}
	}

	&__image {
		height: auto;
		width: 100%;
	}

	.tns-ovh {
		padding-top: rem-calc(8px);
	}
}