.text-media {
    display: flex;
    flex-wrap: wrap;
    gap: rem-calc(20px);
    justify-content: center;

    &__media {
        max-width: rem-calc($text-media-max-media-width);
        width: 100%;

        @include media('>=tablet-wide') {
            flex: 1 1 0;
        }

        img,
        video {
            aspect-ratio: $text-media-aspect-ratio;
            display: block;
            height: auto;
            margin: 0 auto;
            object-fit: cover;
            width: 100%;

            + p:last-child {
                padding-bottom: 0;
                padding-top: Clamp(#{rem-calc(15px)}, #{vw(15px, 320)}, #{rem-calc(30px)});
            }
        }
    }

    &__text {
        @include media('>=tablet-wide') {
            flex: 1 1 0;
            max-width: 50%;
        }

        > .text-center {
            padding-top: var(--text-media-text-padding-top, #{Clamp(#{rem-calc(20px)}, #{vw(30px, 1440px)}, #{rem-calc(30px)})});

            @include media('>=tablet-wide') {
                margin: 0 auto;
                max-width: var(--text-media-text-max-width, rem-calc(466px));
            }

            > *:first-child {
                padding-top: 0;
            }
        }

        > .accordion {
            padding-top: Clamp(#{rem-calc(50px)}, #{vw(70px, 1440px)}, #{rem-calc(70px)});
        }
    }

    &__links {
        display: flex;
        padding-top: Clamp(#{rem-calc(30px)}, #{vw(40px, 1440px)}, #{rem-calc(40px)});

        > * {
            flex: 0 0 50%;
        }
    }

    &--text-only {
        --text-media-text-padding-top: 0;
        --text-media-text-max-width: none;
    }
}
