.pagination {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: rem-calc(24px);
    list-style: none;
    justify-content: center;
    margin: 0;
    padding: 0;

    &__item {
        padding: 0;

        .btn-round {
            height: rem-calc(30px);
            padding: rem-calc(10px);

            img {
                height: rem-calc(9px);
                width: rem-calc(14px);
            }
        }

        &--disabled {
            cursor: not-allowed;
            opacity: 0.5;

            .btn-prev {
                &:hover {
                    img {
                        left: rem-calc(-2px);
                    }
                }
            }

            .btn-next {
                &:hover {
                    img {
                        left: rem-calc(2px);
                    }
                }
            }
        }
    }

    &__link {
        padding: 1em;
    }
}
