$stage-transition-duration: 1s;
$stage-transition-timing-function: ease-in-out;
$stage-media-ratio: 56.25vw;

.stage {
	align-items: flex-end;
	aspect-ratio: 300 / 432;
	display: flex;
	height: auto;
	margin-top: Clamp(#{rem-calc(-171px)}, #{vw(-171px, 1440px)}, #{rem-calc(-91px)});
	width: 100%;

	@include media('>=tablet-wide') {
		aspect-ratio: auto;
		height: calc(#{$stage-media-ratio} + var(--stage-text-height) + #{rem-calc(80px)});
	}

	@include media('>=desktop') {
		height: calc(#{$stage-media-ratio} + #{rem-calc(315px)});;
	}

	@include media('>=1545px') {
		height: calc(#{$stage-media-ratio} + #{rem-calc(100px)});
		transition: height $stage-transition-duration $stage-transition-timing-function, margin-bottom $stage-transition-duration $stage-transition-timing-function;
	}

	.is--scrolled & {
		@include media('>=desktop') {
			height: calc(#{$stage-media-ratio} + var(--stage-text-height) - #{rem-calc(70px)});
		}

		@include media('>=1545px') {
			height: calc(#{rem-calc(1080px)} + var(--stage-text-height) - #{rem-calc(300px)});
		}
	}

	&__headline {
		padding-bottom: rem-calc(40px);
	}

	&__media {
		aspect-ratio: 320 / 429;
		height: auto;
		left: 50%;
		object-fit: cover;
		position: absolute;
		top: 0;
		transform: translateX(-50%);
		z-index: map-get($z-index, "stage-media");
		width: 100vw;

		@include media('>=tablet-wide') {
			aspect-ratio: 1024 / 768;
			transition: width $stage-transition-duration $stage-transition-timing-function;

			.is--scrolled & {
				width: $container-width;
			}
		}

		@include media('>=desktop') {
			aspect-ratio: 1248 / 720;
		}

		@include media('>=1545px') {
			aspect-ratio: 192 / 108;
		}

		&::after {
			background: $gradient-stage;
			bottom: 0;
			content: "";
			height: 76.3%;
			left: 0;
			position: absolute;
			right: 0;

			@include media('>=desktop') {
				height: 66.6667%;
			}
		}

		img,
		video {
			display: block;
			height: 100%;
			object-fit: cover;
			width: 100%;
		}
	}

	.element {
		@include media('>=tablet-wide') {
			transform: translateY(-50%);
			transition: transform $stage-transition-duration $stage-transition-timing-function;
			padding-bottom: Clamp(#{rem-calc(30px)}, #{vw(120px, 1440px)}, #{rem-calc(120px)});

			.is--scrolled & {
				transform: translateY(0);
			}
		}

		@include media('>=desktop') {
			transform: translateY(#{rem-calc(-300px)});
		}

		@include media('>=1545px') {
			transform: translateY(-20%);
		}
	}

	+ .element {
		@include media('>=desktop') {
			padding-top: 0;
		}
	}
}