$btn-border-width: 2px;
$btn-border-radius: em(40px, $font-size-btn);

button {
    background: inherit;
    border: 0 none;
    border-radius: 0;
    color: inherit;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    padding: inherit;
    text-align: inherit;
    text-transform: inherit;

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }

    &:focus-visible {
        outline: 1px solid white;
    }
}

.btn-link {
    &,
    &:link,
    &:visited {
        @include font-button;
        align-items: center;
        display: inline-flex;
        font-size: rem-calc($font-size-btn);
        letter-spacing: rem-calc(3px);
        padding: 0;
        text-decoration: underline;
        text-underline-offset: 0.25em;
    }

    &:not([disabled]) {
        &:hover,
        &:focus-visible {
            text-decoration: none;
        }
    }

    &--medium {
        &,
        &:link,
        &:visited {
            font-size: rem-calc(18px);
        }
    }
}

.btn-icon-link {
    @extend .btn-link;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: rem-calc(10px);
    text-align: center;
    margin: 0 auto;

    @include media('>=tablet') {
        gap: rem-calc(20px);
    }

    > img {
        --btn-icon-size: #{rem-calc(62px)};
        height: var(--btn-icon-size);
        width: var(--btn-icon-size);

        @include media('>=tablet') {
            --btn-icon-size: #{rem-calc(92px)};
        }
    }
}

.btn-primary {
    @include btn-left;

    &,
    &:link,
    &:visited {
        @include font-button;
        align-items: center;
        border-radius: $btn-border-radius;
        display: inline-flex;
        font-size: rem-calc($font-size-btn);
        letter-spacing: rem-calc(3px);
        min-height: em($btn-primary-min-height, $font-size-btn);
        padding: em(15px, $font-size-btn) em(30px, $font-size-btn);
        position: relative;
        text-align: center;
        text-decoration: none;

        &::before {
            @include gradient-border;
            border-radius: $btn-border-radius;
            padding: $btn-border-width;
        }
    }

    &--selected {
        &,
        &:link,
        &:visited,
        &:hover,
        &:focus {
            background: $gradient-form-field;
            padding-inline: em(51.5px, $font-size-btn);

            &::before {
                background: none;
            }
        }
    }
}

.btn-round {
    aspect-ratio: 1;
    height: Clamp(#{rem-calc(61px)}, #{vw(115px, 1440px)}, #{rem-calc(115px)});
    padding: 0;

    &,
    &:link,
    &:visited {
        &::before {
            border-radius: 50%;
        }
    }

    &:hover:not([disabled]) {
        left: auto;
        padding: 0;
    }

    > img {
        height: Clamp(#{rem-calc(10px)}, #{vw(17px, 1440px)}, #{rem-calc(17px)});
        margin: auto;
        width: Clamp(#{rem-calc(17px)}, #{vw(31px, 1440px)}, #{rem-calc(31px)});
    }
}

.btn-next,
.btn-prev {
    img {
        position: relative;
        transition: left 0.25s ease-in-out;
    }
}

.btn-prev {
    &:hover:not([disabled]) {
        img {
            left: rem-calc(-5px);
        }
    }

    img {
        left: rem-calc(-2px);
    }
}

.btn-next {
    &:hover:not([disabled]) {
        img {
            left: rem-calc(5px);
        }
    }

    img {
        left: rem-calc(2px);
    }
}

.btn-group {
    align-items: center;
    display: flex;
    gap: rem-calc(10px);
    justify-content: space-between;

    &:not(&--center) {
        > *:last-child:not(.btn-group__end, .amazon-pay-button-wrapper) {
            @include btn-right;
        }

        > *:first-child:not(.btn-link, .amazon-pay-button-wrapper) {
            @include btn-left;
        }
    }

    &--right {
        justify-content: flex-end;

        // double :only-child:first-child pseudo selector for a higher specificity
        > *:only-child:first-child:not(.btn-link, .amazon-pay-button-wrapper) {
            @include btn-right;
        }
    }

    &--center {
        justify-content: center;

        > * {
            @include btn-center;
        }
    }

    &__end {
        align-items: flex-start;
        display: flex;
        flex: 1 1 rem-calc(100px);
        flex-wrap: wrap;
        gap: rem-calc(20px);
        justify-content: flex-end;

        > *:last-child {
            @include btn-right;
        }
    }
}
