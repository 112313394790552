$button-size: 40px;
$popover-background-size: 15px;

.popover-toggle {
    background-image: url("./../../images/info-white.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: rem-calc($popover-background-size) auto;
    height: rem-calc($button-size);
    margin: rem-calc(div(($popover-background-size - $button-size), 2) div(($popover-background-size - $button-size), 2) 0 div(($popover-background-size - $button-size), 2));
    padding: 0;
    vertical-align: middle;
    width: rem-calc($button-size);
}

.popover {
    @include font-small;
    --link-color: var(--color-primary);
    background: white;
    border-radius: rem-calc(10px);
    box-shadow: 0 0 10px 1px rgba($color-primary, 0.7);
    color: var(--color-primary);
    display: none;
    max-width: rem-calc(300px);
    padding: rem-calc(10px 15px);
    z-index: map-get($z-index, "popover");

    &.is--expanded {
        display: block;
    }

    &::-moz-selection,
    ::-moz-selection {
        background-color: var(--color-primary);
        color: white;
    }

    &::selection,
    ::selection {
        background-color:var(--color-primary);
        color: white;
    }

    > p:last-child {
        padding-bottom: 0;
    }
}
