.checkout {
    display: flex;
    flex-direction: column;
    gap: rem-calc(10px);
    justify-content: center;

    @include media('>=desktop') {
        gap: rem-calc(20px);
        flex-direction: row;
        flex-wrap: wrap;
    }

    &-shipping,
    &-payment,
    &-order,
    &-delivery {
        padding-bottom: rem-calc(20px);
    }

    &-shipping {
        @include media('>=desktop') {
            margin-inline: auto;
            max-width: rem-calc(466px);
        }
    }

    &-payment,
    &-order {
        @include media('>=desktop') {
            flex: 1 1 auto;
            max-width: 50%;
            width: 50%;
        }
    }

    &-order,
    &-delivery {
        > *:first-child {
            padding-top: 0;
        }
    }

    &-delivery,
    &-total {
        @include media('>=550px') {
            padding-left: rem-calc($article-item-padding-left);
        }

        @include media('>=tablet-wide') {
            padding-left: rem-calc($article-item-padding-left-desktop);
        }
    }

    &-order {
        &__top,
        &__bottom {
            @include media('>=550px') {
                padding-left: rem-calc($article-item-padding-left);
            }

            @include media('>=tablet-wide') {
                padding-left: rem-calc($article-item-padding-left-desktop);
            }
        }

        &__items {
            list-style: none;
            margin-bottom: rem-calc(25px);
            margin-top: 0;
            padding: 0;
        }

        &__item {
            padding: rem-calc(0 0 19px);

            @include media('>=tablet-wide') {
                grid-auto-columns: auto;
            }

            &__sum {
                @include font-large;
                grid-row-start: 1;
                grid-row-end: 2;
                text-align: right;
            }
        }

        &__total {
            @include font-large;
            display: flex;
            justify-content: space-between;
            text-transform: uppercase;
        }

        &__change {
            padding-top: rem-calc(8px);
            text-align: right;
        }
    }

    &-delivery {
        @include media('>=desktop') {
            max-width: Clamp(#{rem-calc(466px)}, #{vw(466px, 1248)}, #{rem-calc(588px)});
            margin-left: auto;
            padding-left: 0;
        }

        &__items {
            list-style: none;
            margin: 0;
            padding: 0;

            > *:last-child {
                padding-bottom: 0;
                margin-bottom: 0;
                border: 0 none;
            }
        }

        &__item {
            border-bottom: 1px solid rgba(white, 0.5);
            margin-bottom: rem-calc(20px);
            padding-bottom: rem-calc(20px);

            @include media('>=550px') {
                display: grid;
                gap: rem-calc(10px 30px);
                grid-auto-columns: minmax(auto, 225px) 1fr;
                justify-content: space-between;
            }

            @include media('>=desktop') {
                grid-auto-columns: minmax(auto, 225px) minmax(auto, 345px);
            }

            > *:last-child {
                padding-bottom: 0;
            }

            &__change {
                text-align: right;
            }

            &__body {
                padding-bottom: rem-calc(25px);

                @include media('>=550px') {
                    grid-column-start: 2;
                    padding-bottom: 0;
                }

                > div + div {
                    padding-top: rem-calc(8px);
                }
            }

            &__head {
                @include font-large;
                padding-bottom: rem-calc(10px);

                @include media('>=550px') {
                    grid-column-start: 1;
                    grid-column-end: 2;
                    padding-bottom: 0;
                }
            }
        }
    }

    &-total {
        width: 100%;

        &::before {
            background-color: rgba(white, 0.5);
            content: "";
            display: block;
            height: 5px;
            margin-bottom: rem-calc(25px);
        }

        &__sum {
            display: grid;
            grid-template-columns: 1fr 1fr;
            justify-content: space-between;
            padding-bottom: var(--checkout-sum-padding-bottom, 1.5em);
        }

        &__sum-group {
            --checkout-sum-padding-bottom: 0;
            padding-bottom: 1.5em;
        }

        &__price {
            padding: rem-calc(0 0 5px);
            text-align: right;

            @include media('>=550px') {
                min-width: rem-calc(160px);
                padding: rem-calc(3px 0 0 0);
            }
        }

        &__total {
            @include font-large;
            padding: rem-calc(12px 0 40px);
            text-transform: uppercase;
        }
    }

    &-confirmation {
        --element-padding-bottom: Clamp(#{rem-calc(23px)}, #{vw(23px, 320px)}, #{rem-calc(40px)});
        --element-padding-top: 0;
        border-bottom: 1px solid rgba(white, 0.5);
        margin-bottom: Clamp(#{rem-calc(23px)}, #{vw(23px, 320px)}, #{rem-calc(40px)});
    }
}
