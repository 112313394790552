@mixin btn-center {
    @include box-shadow-focus(focus-visible);
    --transition-override: box-shadow 0.25s ease-in, padding ease-in-out 0.45s;

    @include media('>=tablet-wide') {
        &:hover:not([disabled]) {
            left: 0;
            right: 0;
        }
    }
}
