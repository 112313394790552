.voucher {
	@include media('>=tablet') {
		display: grid;
		grid-template-columns: var(--voucher-grid-template-columns, repeat(2, 1fr));
		grid-auto-rows: var(--voucher-grid-auto-rows, auto auto auto);
		grid-column-gap: rem-calc(15px);
	}

	@include media('>=tablet-wide') {
        --voucher-grid-auto-rows: auto auto auto 1fr;
        --voucher-grid-template-columns: repeat(3, 1fr);
		grid-column-gap: rem-calc(20px);
	}

    &--service-voucher {
        @include media('>=tablet') {
            --voucher-grid-auto-rows: auto auto auto auto auto;
        }

        @include media('>=tablet-wide') {
            --voucher-grid-auto-rows: auto auto auto auto auto auto 1fr;
        }

        @include media('>=desktop') {
            --voucher-grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    > .step-divider {
        .voucher--service-voucher & {
            @include media('>=tablet') {
                grid-area: 1 / 1 / 2 / 3;
            }

            @include media('>=tablet-wide') {
                grid-area: 1 / 1 / 2 / 4;
            }

            @include media('>=desktop') {
                grid-area: 1 / 1 / 2 / 3;
            }
        }
    }

    &__description {
        .voucher--service-voucher & {
            border-bottom: 1px solid rgba(white, 0.5);
            padding-bottom: Clamp(#{rem-calc(25px)}, #{vw(40px, 1440px)}, #{rem-calc(40px)});
            margin-bottom: Clamp(#{rem-calc(25px)}, #{vw(40px, 1440px)}, #{rem-calc(40px)});

            @include media('>=tablet') {
                grid-area: 2 / 1 / 3 / 3;
            }

            @include media('>=tablet-wide') {
                grid-area: 2 / 1 / 3 / 4;
            }

            @include media('>=desktop') {
                grid-area: 2 / 2 / 3 / 3;
            }

            > * {
                max-width: Clamp(#{rem-calc(256px)}, 80vw, #{$content-wrap-max-width});
            }

            > *:last-child {
                padding-bottom: 0;
            }
        }
    }

	&__preview {
		margin-bottom: var(--voucher-preview-margin-bottom, #{rem-calc(26px)});
		margin-inline: auto;
		width: 100%;

		@include media('>=tablet') {
			grid-area: var(--voucher-preview-grid-area, #{1 / 1 / 3 / 2});
		}

		@include media('>=tablet-wide') {
            --voucher-preview-grid-area: 1 / 1 / 5 / 2;
			margin-block: 0;
		}

        .voucher--service-voucher & {
            @include media('>=tablet') {
                --voucher-preview-margin-bottom: 0;
                --voucher-preview-grid-area: 3 / 1 / 6 / 2;
            }

            @include media('>=tablet-wide') {
                --voucher-preview-grid-area: 3 / 1 / 7 / 2;
            }

            @include media('>=desktop') {
                --voucher-preview-grid-area: 2 / 1 / 6 / 2;
            }
        }
	}

	&__image {
		align-items: center;
		display: flex;
		justify-content: center;
		margin: auto;
		max-width: rem-calc(467px);
		min-height: 100vw;
		position: relative;
		width: 100%;

		@include media('>=phone-wide') {
			min-height: rem-calc(509px);
		}

		&::before,
		&::after {
			content: "";
			display: block;
			position: absolute;
			z-index: map-get($z-index, "voucher-image-before")
		}

		&::before {
			aspect-ratio: 1;
			background-color: $purple;
			border-radius: 50%;
			width: 116%;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		&::after {
			aspect-ratio: 714 / 370;
			background: url("./../../images/glitter-white.svg") no-repeat center;
			background-size: 116%;
			left: -40%;
			mix-blend-mode: lighten;
			top: 17%;
			width: 158%;
		}

		img {
			display: block;
			height: auto;
			margin-inline: auto;
			width: var(--voucher-image-width, 84.25%);

			@include media('>=phone-wide') {
				height: auto;
			}

			@include media('>=tablet-wide') {
				width: var(--voucher-image-width, 100%);
			}
		}
	}

	&__cover {
		max-width: rem-calc(383px);

		@include media('<phone-wide') {
			--voucher-image-width: 100%;
		}

		@include media('>=tablet-wide', '<desktop-wide') {
			--voucher-image-width: 84.25%;
		}
	}

	&__value {
		padding-bottom: rem-calc(23px);

		@include media('>=tablet') {
			grid-area: var(--voucher-value-grid-area, #{1 / 2 / 2 / 3});
		}

		@include media('>=tablet-wide') {
            --voucher-value-grid-area: 1 / 2 / 4 / 3;
		}

		> * {
			margin-inline: auto;
			max-width: rem-calc(349px);
		}

		.amount-input {
			margin-inline: auto;

			@include media('>=tablet-wide') {
				margin-right: var(--amount-input-margin-right, 0);
			}
		}

        .voucher--service-voucher & {
            @include media('>=tablet') {
                --voucher-value-grid-area: 3 / 2 / 4 / 3;
            }

            @include media('>=tablet-wide') {
                --voucher-value-grid-area: 3 / 2 / 4 / 4;
                --amount-input-margin-right: auto;
            }

            @include media('>=desktop') {
                --voucher-value-grid-area: 3 / 2 / 4 / 3;
            }
        }
	}

	&__options {
		@include media('<tablet') {
			margin-bottom: rem-calc(13px);
		}

		@include media('>=tablet') {
			grid-area: var(--voucher-options-grid-area, #{2 / 2 / 3 / 3});
			padding-bottom: rem-calc(23px);
		}

		@include media('>=tablet-wide') {
            --voucher-options-grid-area: 1 / 3 / 4 / 4;
		}

		> * {
			margin-inline: auto;
			max-width: rem-calc(349px);
		}

        .voucher--service-voucher & {
            @include media('>=tablet') {
                --voucher-options-grid-area: 4 / 2 / 5 / 3;
            }

            @include media('>=tablet-wide') {
                --voucher-options-grid-area: 4 / 2 / 5 / 4;
            }

            @include media('>=desktop') {
                --voucher-options-grid-area: 4 / 2 / 5 / 3;
            }

            > .form__field {
                @include media('>=tablet-wide') {
                    display: flex;
                    gap: rem-calc(40px);
                    max-width: fit-content;
                }
            }
        }
	}

	&__dedication {
		border-top: 1px solid rgba(white, 0.5);
		padding-top: $form-field-padding-bottom;
	}

	&__block-end {
		@include media('>=tablet') {
			grid-area: var(--voucher-block-end-grid-area, #{3 / 1 / 4 / 3});
		}

		@include media('>=tablet-wide') {
            --voucher-block-end-grid-area: 4 / 2 / 5 / 4;
		}

        .voucher--service-voucher & {
            @include media('>=tablet') {
                --voucher-block-end-grid-area: 6 / 1 / 7 / 3;
            }

            @include media('>=tablet-wide') {
                --voucher-block-end-grid-area: 6 / 2 / 8 / 4;
            }

            @include media('>=desktop') {
                --voucher-block-end-grid-area: 5 / 2 / 6 / 3;
            }

            .article__add-to-cart {
                @include media('>=desktop') {
                    grid-template-columns: minmax(#{rem-calc(160px)}, auto) rem-calc(160px) rem-calc(263px);
                }
            }
        }
	}

	.cart__bottom {
		padding-left: 0;
	}
}
