$checkbox-size: rem-calc(20px);
$form-label-font-size: Clamp(#{rem-calc(16px)}, #{vw(18px, 1440px)}, #{rem-calc(18px)});

.form {
    $block: &;

    &__label {
        display: block;
        font-size: Clamp(#{rem-calc(16px)}, #{vw(18px, 1440px)}, #{rem-calc(18px)});
        padding-bottom: rem-calc(5px);

        span {
            margin-left: 0.25em;
        }
    }

    &__input {
        @include form-field;
        min-height: rem-calc($form-field-min-height);
        padding: em(14px, 20px) $form-field-padding-x;

        &[type=number] {
            -moz-appearance: textfield;
        }

        &[type=date] {
            appearance: none;

            &::-webkit-date-and-time-value {
                text-align: left;
            }
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    &__input,
    &__textarea {
        &:not([readonly]) {
            @include box-shadow-focus(focus);
        }
    }

    &__input,
    &__textarea {
        &::-moz-selection {
            background-color: var(--color-primary);
            color: white;
        }

        &::selection {
            background-color:var(--color-primary);
            color: white;
        }
    }

    &__input-group {
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        width: 100%;

        > #{$block}__input {
            border-radius: $form-field-border-radius 0 0 $form-field-border-radius;
            border-width: $form-field-border-width var(--error-border-width, 0) $form-field-border-width $form-field-border-width;
            flex: 1 1 auto;
            min-width: 0;
            overflow: hidden;
            position: relative;
            text-overflow: ellipsis;
            width: 1%;
        }

        > #{$block}__submit {
            @include font-button;
            @include gradient-transition;
            background: $gradient-form-field;
            border-color: $color-border;
            border-radius: 0 $form-field-border-radius $form-field-border-radius 0;
            border-style: solid;
            border-width: 1px 1px 1px 0;
            color: white;
            flex: 0 0 auto;
            font-size: rem-calc($font-size-btn);
            letter-spacing: rem-calc(3px);
            overflow: hidden;
            padding: 0 em(30px, $font-size-btn);
            position: relative;
            z-index: map-get($z-index, "input-group-submit");
            transition: background 0.5s ease-in;

            &::before {
                z-index: map-get($z-index, "hover-input-group-button");
            }

            &:hover::before {
                opacity: 1;
            }

            &:not([readonly]) {
                box-shadow: 0 0 0 0 transparent;
                transition: box-shadow 0.25s ease-in;

                &:focus {
                    box-shadow: $btn-focus-shadow;
                    outline: 0;
                }
            }

            span {
                @include media('<phone-wide') {
                    display: none;
                }
            }
        }
    }

    &__textarea {
        @include form-field;
        max-width: 100%;
        padding: em(14px, 20px) $form-field-padding-x;
    }

    &__select-wrapper {
        background: $form-field-background;
        border: $form-field-border-width solid var(--error-border-color, #{$color-border});
        border-radius: $form-field-border-radius;
        position: relative;

        &:hover {
            #{$block}__select-arrow-box::before {
                opacity: 1;
            }
        }
    }

    &__select {
        @include font-basic;
        appearance: none;
        background: none transparent;
        border: $form-field-border-width solid var(--error-border-color, #{$color-border});
        border-radius: $form-field-border-radius;
        box-shadow: var(--error-box-shadow, 0 0 0 0 transparent);
        color: $form-field-color;
        margin: 0;
        transition: var(--transition-override, #{box-shadow 0.25s ease-in});
        font-size: inherit;
        min-height: rem-calc($form-field-min-height);
        padding:
            Clamp(#{rem-calc(15px)}, #{vw(14px, 1440px)}, #{rem-calc(14px)})
            calc(#{$form-field-padding-x} + #{$select-arrow-box-width})
            Clamp(#{rem-calc(15px)}, #{vw(14px, 1440px)}, #{rem-calc(14px)})
            $form-field-padding-x;
        width: 100%;

        &:focus {
            box-shadow: var(--error-box-shadow, 0 0 0 0 transparent), #{$btn-focus-shadow};
            outline: 0;
        }

        option {
            font-size: rem-calc(16px);
        }
    }

    &__select-arrow-box {
        @include gradient-transition;
        align-items: center;
        background: $gradient-form-field;
        border-radius: 0 $form-field-border-radius $form-field-border-radius 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        margin: 1px 1px 1px 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        width: $select-arrow-box-width;

        &::before {
            border-radius: 0 $form-field-border-radius $form-field-border-radius 0;
            z-index: map-get($z-index, "hover-select-button");
        }
    }

    &__checkbox-outer,
    &__radio-outer {
        margin-bottom: rem-calc(13px);
        position: relative;

        &::before {
            background: transparent;
            border: solid 1px transparent;
            border-radius: 50%;
            box-shadow: 0 0 0 0 transparent;
            content: "";
            display: block;
            height: $checkbox-size;
            padding: 1px;
            position: absolute;
            transition: box-shadow 0.25s ease-in;
            width: $checkbox-size;
            z-index: map-get($z-index, "gradient-border");
        }

        &:focus-within::before {
            box-shadow: #{$btn-focus-shadow};
        }

        &:has(+ [hidden]:last-child),
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__checkbox,
    &__radio {
        @include visually-hidden;
        height: rem-calc(22px) !important;

        + #{$block}__label {
            padding-bottom: 0;
            padding-left: rem-calc(37px);
            user-select: none;

            &::before {
                @include gradient-border;
                box-shadow: var(--error-box-shadow, 0 0 0 0 transparent);
                height: $checkbox-size;
                padding: 1px;
                transition: box-shadow 0.25s ease-in;
                width: $checkbox-size;
            }

            &::after {
                content: "";
                display: block;
                position: absolute;
                transform: scale(0);
                transform-origin: center;
                transition: transform 0.25s ease-in;
            }
        }

        &:checked + #{$block}__label {
            &::after {
                transform: scale(1);
            }
        }
    }

    &__checkbox {
        &:checked + #{$block}__label {
            &::after {
                height: rem-calc(12px);
                width: rem-calc(14px);
            }
        }

        + #{$block}__label {
            &::after {
                background-image: url("./../../images/check-gold.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100% auto;
                left: rem-calc(5px);
                top: rem-calc(5px);
            }
        }
    }

    &__radio {
        + #{$block}__label {
            &::before,
            &::after {
                border-radius: 50%;
            }

            &::after {
                background-color: var(--color-secondary);
                height: rem-calc(9px);
                left: rem-calc(7.5px);
                top: rem-calc(7.5px);
                width: rem-calc(9px);
            }
        }
    }

    &__fieldset {
        border: none;
        margin: 0;
        padding: 0;
    }

    > #{$block}__fieldset:first-child #{$block}__legend {
        padding-top: em(20px, 20px);
    }

    &__legend {
        @include font-large;
        padding-bottom: em(25px, 25px);
        padding-top: em(10px, 25px);
        width: 100%;
    }

    &__field {
        border: none;
        padding-bottom: $form-field-padding-bottom;
        padding-inline: 0;

        &--centered {
            margin-inline: auto;
        }
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        margin-inline: Max(#{rem-calc(-7.5px)}, #{rem-calc(-10px)});

        > * {
            flex: 1 1 0;
            min-width: var(--form-column-min-width, #{rem-calc(298px)});
            padding-inline: Max(#{rem-calc(7.5px)}, #{rem-calc(10px)});

            @include media('>=tablet') {
                max-width: Max(#{vw(20px, 1440px)}, 50%);
            }

            @include media('>=tablet-wide') {
                flex-basis: rem-calc(194px);
            }
        }
    }

    &__grid {
        display: grid;
        gap: rem-calc(20px);
        grid-template-columns: repeat(3, 1fr);
    }

    &__plz {
        --form-column-min-width: 0;
        flex-basis: 33%;
        max-width: 33%;

        + * {
            --form-column-min-width: 0;
            max-width: none;
        }
    }

    &__couponcode {
        @include media('>=tablet-wide') {
            --form-column-min-width: 0;
            flex-basis: 50%;
            max-width: 50%;
        }
    }

    &__error {
        --error-border-color: #{$color-error};
        --error-border-width: 2px;
        --error-box-shadow: #{$form-error-box-shadow};

        #{$block}__checkbox-outer,
        #{$block}__radio-outer {
            --checkbox-error-background: #{$color-error};
        }
    }

    &__error-message {
        align-items: center;
        display: flex;
        font-size: $form-label-font-size;
        justify-content: center;
        padding-bottom: rem-calc(25px);

        &,
        & > p {
            align-items: center;
            display: flex;
        }

        > *:last-child {
            padding-bottom: 0;
        }

        .form__field & {
            justify-content: flex-start;
            padding-bottom: 0;
            padding-top: rem-calc(10px);
        }
    }

    &__error-icon {
        flex-grow: 0;
        flex-shrink: 0;
        height: 1em;
        margin-right: em(11px, 18px);
        width: 1em;
    }

    .alert {
        --element-padding-bottom: #{rem-calc(25px)};
        --element-padding-top: 0;
        font-size: Clamp(#{rem-calc(16px)}, #{vw(16px, 320px)}, #{rem-calc(20px)});
    }

    .form__row:last-child .form__field:last-child {
        padding-bottom: 0;
    }
}

::-webkit-input-placeholder {
    color: $color-placeholder;
}

::placeholder {
    color: $color-placeholder;
}
