.lang-select {
	display: flex;
	letter-spacing: rem-calc(0.5px);
	margin: rem-calc(0 -10px 25px -10px);
	padding: 0;

	@include media('>=desktop') {
		margin-bottom: 0;
	}

	&__link {
		&,
		&:link,
		&:visited {
			align-items: center;
			display: flex;
			font-size: rem-calc(18px);
			gap: rem-calc(8px);
			padding: rem-calc(10px);
		}
	}
}