@keyframes fadeInFromTop {
	0% {
		opacity: 0;
		transform: translateY(var(--start-translate-y, -250px)) translateX(var(--start-translate-x, 0)) rotate(var(--start-rotate, 0));
	}

	100% {
		opacity: 1;
		transform: translateY(var(--final-translate-y, 0)) translateX(var(--final-translate-x, 0)) rotate(var(--final-rotate, 0));
	}
}