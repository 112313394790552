.element {
	padding-bottom: var(--element-padding-bottom, Clamp(#{rem-calc(20px)}, #{vw(20px, 320)}, #{rem-calc(60px)}));
	padding-top: var(--element-padding-top, Clamp(#{rem-calc(20px)}, #{vw(20px, 320)}, #{rem-calc(60px)}));
	scroll-margin-top: $scroll-margin-top;

	.page-header:has(.promotion-banner.is--active) + .main & {
		scroll-margin-top: $scroll-margin-top-with-promotion-banner;
	}

	> * {
		&:first-child {
			padding-top: 0;
		}

		&:last-child {
			padding-bottom: 0;
		}
	}

	+ .element {
		--element-padding-top: 0;

		.element {
			--element-padding-top: initial;
		}
	}

	&--padding-top-0 {
		--element-padding-top: 0;
	}

	&.is--animated {
		opacity: 0;
		transform: translateY(24.4%);
		transition: opacity 1.2s ease-in-out, transform 1.2s ease-in-out;

		&.is--visible,
		.no-js & {
			opacity: 1;
			transform: translateY(0);
		}
	}
}
