.address-card {
    + .address-card,
    + .address-cards {
        border-top: 5px solid rgba(white, 0.5);
        margin-top: rem-calc(40px);
        padding-top: rem-calc(25px);
    }

    &__badge {
        margin: 0 0 rem-calc(20px) 0;
    }

    &__address {
        > span {
            display: inline-block;
            padding-bottom: rem-calc(8px);
        }
    }

    &__actions {
        border-top: 1px solid rgba(white, 0.5);
        display: flex;
        flex-wrap: wrap;
        gap: Clamp(#{rem-calc(20px)}, #{vw(20px, 320px)}, #{rem-calc(40px)});
        justify-content: flex-end;
        margin-top: rem-calc(20px);
        padding-top: rem-calc(20px);

        .btn-link {
            display: flex;
            align-items: center;
        }
    }
}
