.section {
	padding-block: Clamp(#{rem-calc($section-padding-block-min)}, #{$section-padding-block-middle}, #{rem-calc($section-padding-block-max)});
	position: relative;
	scroll-margin-top: $scroll-margin-top;

	.page-header:has(.promotion-banner.is--active) + .main & {
		scroll-margin-top: $scroll-margin-top-with-promotion-banner;
	}

	&::before {
		background: var(--section-background);
		bottom: 0;
		content: "";
		display: block;
		left: 50%;
		position: absolute;
		top: 0;
		transform: translateX(-50%);
		width: 100vw;
		z-index: map-get($z-index, "section-before");
	}

	&--purple {
		--section-background: #{$purple};
		--masonry-bg-gradient-color: #{$purple};

		+ .section--purple {
			padding-top: 0;
		}
	}

	&--blue {
		--section-background: #{$gradient-blue};
		--masonry-bg-gradient-color: #{$biscay};

		+ .section--blue {
			padding-top: 0;
		}
	}

	&--replaced {
		margin-top: Clamp(#{rem-calc(-90px)}, #{vw(-90px, 1440)}, #{rem-calc(-$section-padding-block-min)});
	}

	&.is--visible::before {
		animation: fadeIn 2s ease-in;
		animation-fill-mode: both;
	}

	> * {
		&:first-child {
			padding-top: 0;
		}

		&:last-child:not(.calendar) {
			padding-bottom: 0;
		}
	}
}
