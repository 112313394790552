$calendar-entry-border-width: 2px;
$calendar-entry-border-radius: 10px;

.calendar {
	border-bottom: 1px solid rgba(white, 0.5);
	margin-bottom: Clamp(#{rem-calc(20px)}, #{vw(20px, 320)}, #{rem-calc(60px)});
	padding-bottom: Clamp(#{rem-calc(20px)}, #{vw(35px, 1440px)}, #{rem-calc(35px)});

	&__header {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: rem-calc(15px);
		padding-bottom: rem-calc(35px);

		@include media('>tablet-wide') {
			flex-wrap: nowrap;
		}
	}

	&__legend {
		display: flex;
		flex-wrap: wrap;
		gap: em(7px, 16px) em(27px, 20px);
		max-width: rem-calc(999px);

		@include media('<tablet') {
			@include font-small;
		}
	}

	&__label {
		align-items: center;
		display: flex;
		gap: em(3px, 20px);

		&::before {
			background: var(--calendar-label-background, transparent);
			border-color: var(--calendar-label-border-color, transparent);
			border-radius: var(--calendar-label-radius, 50%);
			border-style: solid;
			border-width: var(--calendar-label-border-width, 0);
			content: "";
			display: var(--calendar-label-display, none);
			height: var(--calendar-label-height);
			margin: var(--calendar-label-margin);
			width: var(--calendar-label-width);
		}

		&--promo,
		&--unset,
		&--sold,
		&--closed,
		&--same-day,
		&--available,
		&--low-stock {
			--calendar-label-border-width: #{$calendar-entry-border-width};
			--calendar-label-display: block;
			--calendar-label-height: Max(#{rem-calc(15px)}, #{em(16px, 20px)});
			--calendar-label-width: Max(#{rem-calc(15px)}, #{em(16px, 20px)});
			--calendar-label-margin: 0 Max(#{rem-calc(3px)}, #{em(7px, 20px)}) 0 0;
		}

		&--promo {
			--calendar-label-background: #{$gradient-calendar-promo};
			--calendar-label-display: block;
		}

		&--unset {
			--calendar-label-border-color: #{$color-disabled};
			--calendar-label-display: block;
		}

		&--availability {
			--calendar-label-background: #{$gradient-white-stripes-label};
			--calendar-label-display: block;
			--calendar-label-height: Max(#{rem-calc(12px)}, #{em(17px, 20px)});
			--calendar-label-margin: 0 Max(#{rem-calc(5px)}, #{em(10px, 20px)}) 0 0;
			--calendar-label-radius: 0;
			--calendar-label-width: Max(#{rem-calc(12px)}, #{em(17px, 20px)});
		}

		&--same-day {
			--calendar-label-background: #{$waikawa-gray};
		}

		&--winterdream {
			--calendar-icon-width: Max(#{rem-calc(25px)}, #{em(33px, 20px)});
			--calendar-icon-margin-right: 0;
		}

		&--diamond,
		&--arrangement {
			--calendar-icon-width: Max(#{rem-calc(13px)}, #{em(18px, 20px)});
			--calendar-icon-margin-right: Max(#{rem-calc(2px)}, #{em(9px, 20px)});
			--calendar-icon-margin-top: #{rem-calc(2px)};
		}

		&--special {
			--calendar-icon-width: Max(#{rem-calc(10px)}, #{em(13px, 20px)});
			--calendar-icon-margin-right: Max(#{rem-calc(3px)}, #{em(9px, 20px)});
		}

		&--sold {
			--calendar-label-background: #{$red-devil} none;
		}

		&--available {
			--calendar-label-background: #{$color-available} none;
		}

		&--low-stock {
			--calendar-label-background: #{$color-low-stock} none;
		}

		&--closed {
			--calendar-label-border-color: #{rgba(white, 0.2)};
			--calendar-label-background: #{$cloud-burst} none;
		}

		img {
			height: auto;
			margin-right: var(--calendar-icon-margin-right);
			margin-top: var(--calendar-icon-margin-top, 0);
			width: var(--calendar-icon-width);
		}
	}

	&__nav {
		@include font-large;
		min-width: rem-calc(300px);
		max-width: rem-calc(378px);
		position: relative;

		@include media('>tablet-wide') {
			min-width: rem-calc(340px);
		}

		> *:first-child,
		> *:last-child {
			justify-content: center;
			min-width: rem-calc(74px);
			position: absolute;
			top: 0;
			z-index: map-get($z-index, "control-button");
		}

		> *:first-child {
			left: 0;
		}

		> *:last-child {
			@include btn-right;
		}
	}

	&__nav-value {
		align-items: center;
		display: flex;
		justify-content: center;
		min-height: rem-calc($btn-primary-min-height);
		width: 100%;
		text-align: center;
		padding: 0 em($btn-control-width, 20px);
	}

	&__days,
	&__weekdays {
		display: grid;
		gap: rem-calc(5px);
		grid-template-columns: repeat(7, minmax(0, 1fr));

		@include media('>=tablet') {
			gap: rem-calc(10px);
		}

		> * {
			align-items: flex-start;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			@include media('>=tablet') {
				align-items: center;
			}
		}
	}

	&__days {
		grid-auto-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
		margin-bottom: Clamp(#{rem-calc(10px)}, #{vw(10px, 320)}, #{rem-calc(18px)});
	}

	&__highlight {
		border-radius: var(--calendar-highlight-border-radius, 0);
		height: auto;
		left: var(--calendar-highlight-left, #{$calendar-entry-border-width});
		opacity: var(--calendar-highlight-opacity, 1);
		position: absolute;
		top: var(--calendar-highlight-top, #{$calendar-entry-border-width});
		width: var(--calendar-highlight-width, auto);
	}

	&__entry {
		background-color: var(--calendar-background-color-today, var(--calendar-background-color-set, var(--calendar-background-color-unset)));
		background-image: var(--calendar-background-image, none);
		border-radius: rem-calc($calendar-entry-border-radius);
		color: var(--calendar-day-color, white);
		font-size: Clamp(#{rem-calc(14px)}, #{vw(14px, 320)}, #{rem-calc(20px)});
		padding: Clamp(#{rem-calc(20px)}, #{vw(20px, 320)}, #{rem-calc(35px)}) Clamp(#{rem-calc(3px)}, #{vw(3px, 320)}, #{rem-calc(10px)}) Clamp(#{rem-calc(3px)}, #{vw(3px, 320)}, #{rem-calc(10px)});
		position: relative;
		transition: transform 0.25s ease-in;

		@include media('<tablet') {
			letter-spacing: 0;
		}

		@include media('>=tablet') {
			@include font-large;
		}

		@include media('>=tablet-wide') {
			padding: rem-calc(8px);
		}

		@include media('>=desktop') {
			min-height: rem-calc(80px);
		}

		&--past {
			--calendar-day-color: #{$color-disabled};
		}

		&--unset {
			--calendar-price-color-disabled: #{$color-disabled};
			--calendar-highlight-opacity: 0.6;
			cursor: not-allowed;

			&::before {
				border: $calendar-entry-border-width solid $color-disabled;
				border-radius: rem-calc($calendar-entry-border-radius);
				content: "";
				display: block;
				inset: 0;
				position: absolute;
			}
		}

		&--today {
			--calendar-background-color-today: #{$waikawa-gray};
		}

		&--sold {
			--calendar-background-color-set: #{$red-devil};
			--calendar-day-color: #{$color-disabled};
			--calendar-highlight-opacity: 0.6;
			cursor: not-allowed;
		}

		&--closed {
			--calendar-background-color-set: #{$cloud-burst};
			--calendar-day-color: #{$color-disabled};
			cursor: not-allowed;
		}

		&--winterdream {
			--calendar-highlight-width: Clamp(#{rem-calc(20px)}, #{vw(20px, 320)}, #{rem-calc(43px)});
		}

		&--diamond,
		&--arrangement {
			--calendar-highlight-left: Clamp(#{rem-calc(3px)}, #{vw(3px, 320)}, #{rem-calc(10px)});
			--calendar-highlight-top: Clamp(#{rem-calc(3px)}, #{vw(3px, 320)}, #{rem-calc(7px)});
			--calendar-highlight-width: Clamp(#{rem-calc(10px)}, #{vw(10px, 320)}, #{rem-calc(18px)});
		}

		&--availability {
			--calendar-background-image: #{$gradient-white-stripes};
		}

        &--newday,
		&--special {
			--calendar-highlight-border-radius: 0;
			--calendar-highlight-left: 0;
			--calendar-highlight-top: 0;
			--calendar-highlight-width: Clamp(#{rem-calc(13px)}, #{vw(13px, 320)}, #{rem-calc(21px)});

			@include media('<tablet') {
				--calendar-highlight-border-radius: #{rem-calc(10px 0 0 0)};
			}

			@include media('>=tablet') {
				--calendar-highlight-left: #{rem-calc(10px)};
			}

			&.calendar__entry--availability {
				--calendar-background-image: #{$gradient-golden-stripes};
			}
		}

		&[aria-pressed] {
			--calendar-background-color-set: #{$astronaut};

			&:hover {
				transform: scale(1.05);
			}

			&:hover,
			&:focus-visible {
				--calendar-box-shadow: #{0 0 7px 2px rgba($alto, 0.5)};
				z-index: map-get($z-index, "calendar-entry-hover");
			}

			&::after {
				border-radius: rem-calc($calendar-entry-border-radius);
				box-shadow: var(--calendar-box-shadow, none);
				content: "";
				display: block;
				inset: 0;
				outline: 0;
				position: absolute;
				transition: box-shadow 0.25s ease-in;
			}
		}

		&[aria-pressed=true] {
			--calendar-background-color-set: #{$astronaut};

			&::before {
				@include gradient-border;
				border-radius: rem-calc($calendar-entry-border-radius);
				padding: rem-calc(1px);
			}
		}
	}

	&__entry-inner {
		display: flex;
		flex: 0 1 100%;
		flex-wrap: wrap;
		text-align: center;

		> * {
			flex: 0 1 100%;
		}
	}

	&__weekdays {
		padding: 0;
	}

	&__price {
		align-items: center;
		color: var(--calendar-price-color-disabled, white);
		display: var(--calendar-price-display, flex);
		flex-wrap: wrap;
		font-size: var(--calendar-price-font-size, Clamp(#{rem-calc(7.5px)}, #{vw(7.5px, 320)}, #{rem-calc(12px)}));
		gap: 0.5em;
		justify-content: center;

		@include media('>=tablet') {
			@include font-small;
		}

		&--old {
			--calendar-price-display: block;
		}

		img {
			height: auto;
			width: Clamp(#{rem-calc(8px)}, #{vw(8px, 320)}, #{rem-calc(19px)});
		}
	}

	&__text-short,
	&__text-long {
		color: white;
		display: var(--calendar-text-display, block);
		font-size: Clamp(#{rem-calc(7.5px)}, #{vw(7.5px, 320)}, #{rem-calc(12px)});

		@include media('>=tablet') {
			@include font-small;
		}
	}

	&__text-short {
		@include media('>=tablet') {
			--calendar-text-display: none;
		}
	}

	&__text-long {
		--calendar-text-display: none;

		@include media('>=tablet') {
			--calendar-text-display: block;
		}
	}

	&__today {
		display: block;
		line-height: 1;
		margin-bottom: rem-calc(5px);
	}

	&__promo {
		background: $gradient-calendar-promo-radial;
		border-radius: rem-calc(0 $calendar-entry-border-radius 0 20px);
		content: "";
		width: Clamp(#{rem-calc(16px)}, #{vw(16px, 320)}, #{rem-calc(32px)});
		height: Clamp(#{rem-calc(14px)}, #{vw(14px, 320)}, #{rem-calc(28px)});
		position: absolute;
		right: 0;
		top: 0;

		@include media('>=tablet-wide') {
			background: $gradient-calendar-promo;
			border-radius: rem-calc(0 $calendar-entry-border-radius $calendar-entry-border-radius 0);
			bottom: 0;
			height: auto;
			width: Clamp(#{rem-calc(15px)}, #{vw(15px, 1024)}, #{rem-calc(20px)});
		}
	}

	&__notice {
		> *:last-child {
			padding-bottom: 0;
		}
	}
}
