.breadcrumb {
    padding-bottom: rem-calc(30px);

    @include media('>=740px') {
        padding-bottom: 0;
    }

    &__label {
        background: url("./../../images/arrow-right-white.svg") no-repeat right top rem-calc(3px);
        background-size: rem-calc(13px) auto;
        padding-right: rem-calc(22px);
    }

    &__list {
        padding-bottom: 0;
        padding-left: rem-calc(12px);
    }

    &__item {
        display: inline;

        &::before {
            content: "/   ";
            display: inline;
            margin: rem-calc(0 10px 0 8px);
            vertical-align: middle;
        }

        &:first-child {
            &::before {
                display: none;
            }
        }
    }

    > * {
        display: inline;
    }
}
