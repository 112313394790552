.menu-descriptions {
	margin: 0 0 0 calc(var(--dialog-padding, 0) * -1);
	padding: 0;

	&__item {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		gap: Clamp(#{rem-calc(10px)}, vw(57px, 1440), rem-calc(57px));
		list-style: none;
		margin: 0;
		padding: rem-calc(0 0 25px);

		@include media('<tablet') {
			justify-content: center;
		}

		&:last-child {
			padding-bottom: 0;

			.menu-descriptions__variant {
				margin-bottom: 0;
			}

			.menu-descriptions__text {
				border-bottom: none;
			}
		}
	}

	&__content {
		text-align: center;

		* {
			display: block;
			margin-inline: rem-calc(20px);
			padding: 0;
		}
	}

	&__text {
		border-bottom: 1px solid rgba(white, 0.5);
		flex: 1 1 auto;
		max-width: rem-calc(860px);
		margin-left: rem-calc(20px);

		@include media('>=tablet') {
			flex-basis: 0;
			margin-left: 0;
		}
	}

	&__variant {
		aspect-ratio: 40 / 27;
		background-color: $ebony;
		background-image: url("./../../images/fantissima-menu-variant.png");
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: rem-calc(10px);
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: rem-calc(18px 10px);
		position: relative;
		text-align: center;
		width: rem-calc(210px);
		word-break: break-word;

		@include media('>=tablet') {
			border-radius: rem-calc(0 10px 10px 0);
			margin-bottom: Clamp(#{rem-calc(10px)}, vw(25px, 1440), rem-calc(25px));
			min-height: rem-calc(150px);
		}

		&::before {
			display: none;
		}
	}

	&__variant-image {
		aspect-ratio: 21 / 12;
		border-radius: rem-calc(10px);
		width: rem-calc(210px);

		@include media('>=tablet') {
			border-radius: rem-calc(0 10px 10px 0);
			margin-bottom: Clamp(#{rem-calc(10px)}, vw(25px, 1440), rem-calc(25px));
		}
	}

	&__variant-title {
		@include font-small;

		@include media('>=tablet') {
			border-bottom: 1px solid rgba(white, 0.5);
			margin-bottom: rem-calc(12px);
			padding: rem-calc(15px 0 12px);
		}
	}

	&__variant-capital {
		display: block;
		font-size: rem-calc(40px);
		line-height: 1;
	}

	&__variant-name {
		@include font-small;

		@include media('<tablet') {
			display: none;
		}
	}
}