.nav {
	@include font-button;
	list-style: none;

	&__item {
		padding-bottom: 0;
	}

	a {
		&,
		&:link,
		&:visited {
			text-decoration: none;
		}
	}
}