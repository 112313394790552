.card-select {
    &__options {
        display: flex;
        flex-wrap: wrap;
        gap: rem-calc(10px);
        justify-content: center;
        padding-bottom: rem-calc(10px);

        @include media('>=tablet-wide') {
            gap: rem-calc(20px);
            padding-bottom: rem-calc(30px);
        }
    }

    &__descriptions {
        max-width: rem-calc(466px);
        margin: 0 auto;
    }

    &__option {
        align-items: center;
        border-radius: rem-calc(10px);
        background-color: $astronaut;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        gap: Clamp(#{rem-calc(5px)}, #{vw(10px, 1440px)}, #{rem-calc(10px)});
        max-width: Clamp(#{rem-calc(144px)}, #{vw(223px, 1440)}, #{rem-calc(223px)});
        overflow: hidden;
        padding: Clamp(#{rem-calc(25px)}, #{vw(47px, 1440)}, #{rem-calc(47px)}) Clamp(#{rem-calc(6px)}, #{vw(40px, 1440)}, #{rem-calc(40px)}) Clamp(#{rem-calc(8px)}, #{vw(24px, 1440)}, #{rem-calc(24px)});
        position: relative;
        min-width: rem-calc(144px);

        &--payment {
            background-color: transparent;
            padding: 0;
            max-width: Clamp(#{rem-calc(144px)}, #{vw(161px, 1440)}, #{rem-calc(161px)});

            .card-select__option__label {
                @extend .btn-link;
            }
        }

        &__icon {
            --card-select-option-icon-size: Clamp(#{rem-calc(64px)}, #{vw(100px, 1440)}, #{rem-calc(100px)});

            height: var(--card-select-option-icon-size);
            margin-bottom: rem-calc(5px);
            width: var(--card-select-option-icon-size);
        }

        &__icon-box {
            align-items: center;
            aspect-ratio: div(161, 116);
            background-color: white;
            border-radius: rem-calc(10px);
            display: flex;
            justify-content: center;
            margin-bottom: rem-calc(5px);
            width: 100%;
        }

        &__label {
            @include font-small;
            cursor: pointer;
            text-align: center;

            &::after,
            &::before {
                content: "";
                display: block;
                position: absolute;
            }

            &::before {
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
            }

            &::after {
                background: $gradient-calendar-promo-radial;
                background: url("./../../images/check-outline-circle-white.svg") left 8px bottom 8px no-repeat, $gradient-calendar-promo-radial;
                border-bottom-left-radius: 20px;
                height: rem-calc(74px);
                right: rem-calc(-37px);
                top: rem-calc(-37px);
                transform: scale(0);
                transition: transform 0.2s ease-in;
                width: rem-calc(74px);
            }
        }

        &__input {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;

            &:checked + label {
                &::after {
                    transform: scale(1);
                }
            }
        }

        > *:last-child {
            padding-bottom: 0;
        }
    }
}
