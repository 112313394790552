$article-item-padding-left: 56px;
$article-item-padding-left-desktop: 111px;

.cart {
    &__items {
        list-style: none;
        margin-block: 0;
        padding: 0;

        @include media('>=550px') {
            display: grid;
            grid-auto-rows: 1fr;
        }
    }

    &__item {
        padding: rem-calc(0 0 19px);
    }

    &__coupon {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        gap: rem-calc(10px);
        justify-content: space-between;
        padding-bottom: rem-calc(40px);

        @include media('>=tablet') {
            gap: rem-calc(20px);
        }

        > * {
            flex: 1 1 auto;

            &:first-child {
                @include media('>=tablet') {
                    padding-top: rem-calc(10px);
                }
            }

            &:last-child {
                @include media('>=tablet') {
                    max-width: rem-calc(468px);
                }
            }
        }

        .form {
            @include media('>=tablet') {
                max-width: rem-calc(468px);
            }
        }

        .alert {
            --element-padding-bottom: #{rem-calc(20px)};
            --element-padding-top: #{rem-calc(20px)};
            font-size: Clamp(#{rem-calc(16px)}, #{vw(16px, 320px)}, #{rem-calc(20px)});
        }
    }

    &__bottom {
        @include media('>=550px') {
            padding-left: rem-calc($article-item-padding-left);
        }

        @include media('>=tablet-wide') {
            padding-left: rem-calc($article-item-padding-left-desktop);
        }

        &::before {
            background-color: rgba(white, 0.5);
            content: "";
            display: block;
            height: 1px;
            margin-bottom: rem-calc(25px);
        }

        > *:last-child {
            padding-bottom: 0;
        }
    }

    &__sums {
        margin: 0;
        padding: 0;

        .cart__price {
            text-align: right;
        }
    }

    &__sum {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
        padding-bottom: 1.5em;

        &::after {
            content: "";
            display: block;
            clear: both;
        }

        > * {
            margin: 0;
        }
    }

    &__price {
        margin: 0;
        padding: rem-calc(0 0 5px);

        @include media('>=550px') {
            min-width: rem-calc(160px);
            padding: rem-calc(3px 0 0 0);
            text-align: right;
        }

        @include media('>=desktop') {
            min-width: rem-calc(180px);
        }
    }

    &__total {
        @include font-large;
        padding: rem-calc(12px 0 40px);
        text-transform: uppercase;
    }

    &__readonly-amount {
        padding-inline: 0;
        text-align: right;
    }

    > .alert {
        --element-padding-top: 0;

        @include media('>=tablet-wide') {
            padding-left: rem-calc($article-item-padding-left-desktop);
        }
    }
}
