.meta-navigation {
	display: flex;
	flex-wrap: wrap;
	font-size: Clamp(#{rem-calc(11px), vw(11px, 320), rem-calc(18px)});
	letter-spacing: rem-calc(3px);
	margin: rem-calc(0 -12px);
	padding: 0;

	@include media('>=desktop') {
		gap: rem-calc(20px);
	}

	&__link {
		display: block;
		padding: rem-calc(12px);
	}
}