.masonry {
    @include media('>=tablet') {
        display: grid;
        grid-template-rows: auto 1fr;
    }

    @include media('>=desktop') {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-template-rows: auto 1fr;
        grid-gap: rem-calc(0 20px);
    }

    &__header {
        z-index: map-get($z-index, "masonry-header");

        @include media('>=desktop') {
            grid-area: 1 / 2 / 2 / 3;
        }

        + .masonry__grid-container {
            @include media('>=desktop') {
                margin-top: var(--masonry-offset);
            }
        }
    }

    &__grid-container {
        position: relative;

        @include media('>=desktop') {
            grid-area: 2 / 1 / 3 / 4;
        }
    }

    &__bg {
        &,
        &__video {
            aspect-ratio: 320 / 317;
            height: auto;
            width: 100vw;

            @include media('>=desktop') {
                aspect-ratio: 192 / 108;
            }
        }

        & {
            left: 50%;
            max-height: 100%;
            mix-blend-mode: lighten;
            overflow: hidden;
            position: absolute;
            transform: translateX(-50%);
            top: rem-calc(70px);
            z-index: map-get($z-index, "masonry-bg");

            @include media('>=tablet') {
                top: rem-calc(40px);
            }

            @include media('>=desktop') {
                top: rem-calc(60px);
            }

            &::before {
                background: $gradient-masonry-bg;
                bottom: 0;
                display: block;
                content: '';
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
            }
        }

        &__video {
            display: block;
            object-fit: cover;
        }
    }

    &__grid {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: rem-calc(20px);
        list-style: none;
        margin: 0 auto;
        padding: 0;
        width: fit-content;

        @include media('>=tablet') {
            display: grid;
            grid-gap: rem-calc(40px 20px);
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
        }

        @include media('>=desktop') {
            grid-gap: rem-calc(60px 20px);
            grid-template-columns: repeat(3, 1fr);
        }

        &__item {
            padding: 0;
            opacity: var(--masonry-item-opacity, 0);
            transition: var(--masonry-item-transition, (opacity 0.6s ease-in));

            @include media('>=tablet') {
                grid-area: var(--masonry-item-grid-area);
            }

            @include media('>=tablet-wide') {
                --masonry-item-opacity: 1;
            }

            &.is--visible,
            .no-js & {
                --masonry-item-opacity: 1;
            }

            .masonry__grid-container.is--visible & {
                @include media('>=tablet-wide') {
                    --masonry-item-transition: opacity 1.2s ease-in;
                    animation: fadeInFromBottom 1s ease-in-out both;
                }

                &:nth-child(1),
                &:nth-child(2) {
                    @include media('>=tablet-wide') {
                        animation: fadeInFromTop 1s ease-in-out both;
                    }
                }

                &:nth-child(3) {
                    @include media('>=desktop') {
                        animation: fadeInFromRight 1s ease-in-out both;
                    }
                }
            }
        }
    }
}
