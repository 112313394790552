.toastbar {
	background-color: $midnight;
	bottom: 0;
	color: white;
	left: 0;
	padding-block: rem-calc(25px);
	position: fixed;
	right: 0;
	text-align: center;
	z-index: map-get($z-index, "toastbar");
}
