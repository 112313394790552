$color-primary: #131f48;
$color-secondary: #AE8800;
$color-primary-darker: #021A3E;

$color-placeholder: $color-primary-darker;
$color-border: $color-primary-darker;
$color-error: #FF001D;

$color-password-error: #E10000;
$color-password-success: #00E409;

$color-backdrop: rgba($color-primary-darker, 0.8);

$color-disabled: rgba(white, 0.4);

$olive: #977600;
$purple: #2F004B;
$biscay: #1A2960;
$ebony: #111828;
$ripe-plum: #2F004B;
$tangaroa: #021635;
$midnight: #01132F;
$alto: #D9D9D9;
$waikawa-gray: #596899;
$astronaut: #2f4280;
$red-devil: #870015;
$cloud-burst: #1c284d;

$color-available: #3A5E2D;
$color-low-stock: #AB671F;

$gradient-body: linear-gradient(0deg, var(--color-primary), var(--color-primary)), $color-primary-darker;
$gradient-border: linear-gradient(93.09deg, rgba(white, 0) 2.67%, rgba(white, 0.6) 53.38%, rgba(white, 0) 99.07%),
linear-gradient(93.24deg, var(--color-secondary) 0.91%, var(--color-secondary) 99.2%);
$gradient-blue: linear-gradient(0deg, $biscay, $biscay), linear-gradient(0deg, $ebony, $ebony), $ripe-plum;
$gradient-password-strength: linear-gradient(90deg, $color-password-error 0%, $color-password-success 100%);
$gradient-form-field: linear-gradient(89.3deg, rgba(white, 0) -59.16%, rgba(white, 0.12) 42.27%, rgba(white, 0) 133.65%),
linear-gradient(93.24deg, $olive 0.91%, $color-secondary 99.2%);
$gradient-form-field-hover: linear-gradient(89.3deg, rgba(white, 0) -59.16%, rgba(white, 0.12) 52.27%, rgba(white, 0) 133.65%),
linear-gradient(93.24deg, darken($olive, 3) 0.91%, darken($color-secondary, 3) 99.2%);
$gradient-header: linear-gradient(180deg, rgba(black, 0.8) 0%, rgba(black, 0) 61.57%);
$gradient-calendar-promo: linear-gradient(123deg, rgba(white, 0.21) 0%, rgba(white, 0.18) 48.44%, rgba(white, 0.00) 100%), linear-gradient(118deg, $color-secondary 0%, $color-secondary 94.77%);
$gradient-calendar-promo-radial: radial-gradient(734.47% 81.09% at 31.82% 75.00%, rgba(white, 0.21) 0%, rgba(white, 0.18) 48.44%, rgba(white, 0.00) 100%), linear-gradient(118deg, $color-secondary 0%, $color-secondary 94.77%);
$gradient-white-stripes-label:  repeating-linear-gradient(45deg, white 0.5px, transparent 0.5px, transparent 2.5px, white 2.5px, white 3.5px, transparent 3.5px, transparent 5.5px);
$gradient-white-stripes: repeating-linear-gradient(45deg, transparent 1px, rgba(white, 0.3) 1px, rgba(white, 0.3) 2px, transparent 2px, transparent 14px, rgba(white, 0.3) 14px, rgba(white, 0.3) 15px);
$gradient-golden-stripes: repeating-linear-gradient(45deg, transparent 1px, rgba($color-secondary, 0.6) 1px, rgba($color-secondary, 0.6) 2px, transparent 2px, transparent 14px, rgba($color-secondary, 0.6) 14px, rgba($color-secondary, 0.6) 15px);
$gradient-teaser-hover: linear-gradient(180deg, rgba(black, 0.00) 0%, rgba(black, 0.60) 66.15%), rgba(black, 0.30);
$gradient-teaser-image: linear-gradient(180deg, rgba(black, 0.41) 1.48%, rgba(black, 0.00) 18.48%, rgba(black, 0.55) 58.78%), rgba(black, 0.15);
$gradient-stage: linear-gradient(180deg, rgba(black, 0) 0%, rgba(black, 0.8) 100%);
$gradient-masonry-bg: linear-gradient(180deg, var(--masonry-bg-gradient-color, var(--color-primary)) 0%, rgba(black, 0) 18.75%, rgba(black, 0) 83.33%, var(--masonry-bg-gradient-color, var(--color-primary)) 100%);
$gradient-article-fantissima-presale: linear-gradient(93deg, rgba(255, 255, 255, 0.21) 2.67%, rgba(255, 255, 255, 0.18) 49.37%, rgba(255, 255, 255, 0.00) 99.07%), linear-gradient(74deg, #AE8800 18.31%, #AE8800 54.12%);
$gradient-article-promotion-ticket: linear-gradient(93deg, rgba(255, 255, 255, 0.21) 2.67%, rgba(255, 255, 255, 0.18) 49.37%, rgba(255, 255, 255, 0.00) 99.07%), linear-gradient(74deg, #AE8800 18.31%, #AE8800 54.12%);
