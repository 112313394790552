.page-header {
	background: $gradient-header;
	left: 0;
	padding-bottom: Clamp(#{rem-calc(82px)}, #{vw(104px, 1440)}, #{rem-calc(104px)});
	overflow: hidden;
	pointer-events: none;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: map-get($z-index, "header");

	&:has(.promotion-banner) {
		top: calc(var(--promotion-banner-height, 135px) * -1);
		transition: top $promotion-banner-transition;

		@include media('>=tablet') {
			top: calc(var(--promotion-banner-height, 75px) * -1);
		}
	}

	&:has(.promotion-banner.is--active) {
		top: 0;
	}

	&__container {
		display: grid;
		gap: rem-calc(18px);
		grid-template-columns: 1fr auto 1fr;
		grid-template-rows: auto;
	}

	&__logo {
		padding-top: Clamp(#{rem-calc(7px)}, #{vw(20px, 1440)}, #{rem-calc(20px)});
		width: Clamp(#{rem-calc(100px)}, #{vw(224px, 1440)}, #{rem-calc(224px)});

		img {
			display: block;
			height: auto;
			width: 100%;
		}
	}

	&__icon {
		display: inline-block;
		margin-top: Clamp(#{rem-calc(13px)}, #{vw(39px, 1440)}, #{rem-calc(39px)});
		text-decoration: none;
		width: Clamp(#{rem-calc(30px)}, #{vw(48px, 1440)}, #{rem-calc(48px)});

		> svg {
			height: 100%;
			width: 100%;
		}
	}

	&__shop-icons {
		justify-self: end;

		> * {
			margin-inline: rem-calc(6px);
			position: relative;

			@include media('>=tablet-wide') {
				margin-inline: rem-calc(35px);
			}

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	a,
	button {
		pointer-events: auto;
	}
}
