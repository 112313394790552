.dropdown-menu {
	position: relative;

	&__btn {
		@extend .form__select;
		background: none white;

		+ .dropdown-menu__content {
			opacity: 0;
			visibility: hidden;
		}

		&[aria-expanded="true"] {
			--section-menu-arrow-transform: rotate(360deg);
			border-radius: rem-calc(25px) rem-calc(25px) 0 0;

			+ .dropdown-menu__content {
				opacity: 1;
				visibility: visible;
			}
		}

		* {
			pointer-events: none;
		}
	}

	&__arrow {
		@extend .form__select-arrow-box;
		@extend .section-menu__arrow;
	}

	&__content {
		--link-color: black;
		background-color: white;
		border-color: $color-border;
		border-radius: 0 0 $form-field-border-radius $form-field-border-radius;
		border-style: solid;
		border-width: 0 1px 1px 1px;
		left: 0;
		overflow: hidden;
		position: absolute;
		right: 0;
		z-index: map-get($z-index, "section-menu-list");
	}

	&__list {
		border-bottom: 1px solid $color-border;
		list-style: none;
		margin: 0 $form-field-padding-x;
		padding: Clamp(#{rem-calc(7px)}, #{vw(6px, 1440px)}, #{rem-calc(6px)}) 0;

		&:last-child {
			border-bottom: none;
		}
	}

	&__list-item {
		color: black;
		cursor: pointer;
		margin: 0 (-$form-field-padding-x);
		padding: Clamp(#{rem-calc(5px)}, #{vw(4px, 1440px)}, #{rem-calc(4px)}) $form-field-padding-x;

		&:hover {
			background-color: $alto;
		}
	}

	&__label {
		display: block;
		margin: Clamp(#{rem-calc(-5px)}, #{vw(-4px, 1440px)}, #{rem-calc(-4px)}) ($form-field-padding-x * -1);
		padding: Clamp(#{rem-calc(5px)}, #{vw(4px, 1440px)}, #{rem-calc(4px)}) $form-field-padding-x;

		&:focus-visible {
			outline: 2px currentColor solid;
		}

		&:link,
		&:visited {
			text-decoration: none;
		}
	}

	&__label--selected {
		background-color: $alto;
		font-weight: bold;
	}

	&__radio {
		@include visually-hidden;

		&:checked + label {
			background-color: $alto;
		}

		&:focus + label {
			outline: 1px solid $color-border;
		}
	}
}
