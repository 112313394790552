.password-strength {
    @include font-small;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: rem-calc(16px);

    &__label {
        margin-right: rem-calc(25px);
    }

    &__progress[value] {
        appearance: none;
        background: white;
        border: none;
        border-radius: 10px;
        height: rem-calc(10px);
        max-width: rem-calc(345px);
        width: 100%;

        @include media('>=phone-wide') {
            min-width: rem-calc(280px);
        }

        &::-webkit-progress-bar {
            background: white;
            border-radius: 10px;
        }

        &::-webkit-progress-value {
            background: $gradient-password-strength;
            background-size: rem-calc(345px 10px);
            border-radius: 10px;
        }

        &::-moz-progress-bar {
            background: $gradient-password-strength;
            background-size: 345px 10px;
            border-radius: 10px;
        }
    }
}
