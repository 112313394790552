$step-count-circle-size: Clamp(#{rem-calc(52px)}, #{vw(70px, 1440px)}, #{rem-calc(70px)});

.step-divider {
	@include font-large;
	@include font-basic;
	padding: 0 0 Clamp(#{rem-calc(23px)}, #{vw(40px, 1440px)}, #{rem-calc(40px)});
	text-align: center;
	text-transform: none;

	&__count {
		@include font-headline;
		align-items: center;
		border: 2px solid white;
		border-radius: 50%;
		display: grid;
		font-size: $font-size-h2;
		height: $step-count-circle-size;
		justify-content: center;
		margin: 0 auto Clamp(#{rem-calc(7px)}, #{vw(13px, 1440px)}, #{rem-calc(13px)});
		width: $step-count-circle-size;
	}
}