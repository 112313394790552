.hamburger {
	display: grid;

	&__close {
		opacity: 0;
		transform: scale(0);
		transition: none;
		visibility: hidden;
	}

	&.is--expanded {
		.hamburger__open {
			display: none;
		}

		.hamburger__close {
			opacity: 1;
			transform: scale(1);
			transition: opacity 0.8s, transform 0.78s;
			visibility: visible;
		}
	}

	> * {
		grid-column-start: 1;
		grid-row-start: 1;
		pointer-events: none;
	}
}