.info-panel {
    --element-padding-bottom: Clamp(#{rem-calc(40px)}, #{vw(40px, 320)}, #{rem-calc(75px)});
    --element-padding-top: Clamp(#{rem-calc(25px)}, #{vw(25px, 320)}, #{rem-calc(75px)});
    margin-block: Clamp(#{rem-calc(-$section-padding-block-max)}, #{-$section-padding-block-middle}, #{rem-calc(-$section-padding-block-min)});
    margin-inline: Clamp((-100vw + #{$container-width-min}) / 2, (-100vw + #{$container-width-middle}) / 2, (-100vw + #{$container-width-max}) / 2);
    overflow: hidden;
    padding-inline: Clamp(#{rem-calc(30px)}, #{vw(30px, 480)}, #{rem-calc(100px)});
    position: relative;

    @include media('>=tablet') {
        margin-inline: 0;
    }

    @include media('>=desktop-wide') {
        min-height: rem-calc(500px);
    }

    &::before {
        --circle-max-width: 165%;
        animation: scaleUpCircle 0.75s linear both;
        aspect-ratio: 1;
        background-color: $purple;
        border-radius: 50%;
        content: "";
        display: block;
        height: auto;
        left: 50%;
        position: absolute;
        top: 0;
        transform: translate(-50%, -50%);
        z-index: map-get($z-index, "info-panel-before");

        @include media('>=phone-wide') {
            --circle-max-width: 120%;
        }

        @include media('>=tablet') {
            --circle-max-width: 100%;
            transform: translate(-50%, -38.65%);
        }
    }

    &__icon {
        height: auto;
        margin-bottom: Clamp(10px, #{vw(22px, 1440)}, 22px);
        width: Clamp(60px, #{vw(124px, 1440)}, 124px);
    }
}
