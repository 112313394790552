@mixin btn-right {
    @include box-shadow-focus(focus-visible);
    --transition-override: box-shadow 0.25s ease-in, right ease-in-out 0.45s, padding ease-in-out 0.45s;

    &,
    &:link,
    &:visited {
        left: auto;
        right: 0;
    }

    @include media('>=tablet-wide') {
        &:hover:not([disabled]) {
            left: auto;
            right: $btn-hover-indent;
        }
    }
}
