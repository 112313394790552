$content-wrap-max-width: rem-calc(949px);

.content-wrap {
    margin-inline: auto;
    max-width: $content-wrap-max-width;

    @include media('>=tablet-wide') {
        width: Clamp(#{rem-calc(256px)}, 80vw, #{$content-wrap-max-width});
    }

    > * {
        &:first-child {
            padding-top: var(--element-padding-top, 0);
        }

        &:last-child {
            padding-bottom: var(--element-padding-bottom, 0);
        }
    }

    h1,
    .display-h1 {
        max-width: rem-calc(752px);
    }
}
