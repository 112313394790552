@mixin gradient-border {
    background: var(--checkbox-error-background, #{$gradient-border});
    border: solid 1px transparent;
    content: "";
    display: block;
    inset: 0;
    mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    position: absolute;
    z-index: map-get($z-index, "gradient-border");
}
