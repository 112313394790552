.navigation-layer {
    background-color: $navigation-layer-bg-color;
    display: block;
    left: 0;
    top: 0;
    max-height: 100dvh;
    opacity: 0;
    overflow-x: hidden;
    overflow-y: auto;
    pointer-events: auto;
    position: fixed;
    transform: scale(0.5);
    transform-origin: left top;
    visibility: hidden;
    width: 100dvw;
    z-index: map-get($z-index, "navigation-layer");

    nav {
        display: var(--navigation-nav-display, none);
    }

    a,
    button {
        visibility: var(--navigation-link-visibility, hidden);
    }

    &.is--opened {
        --navigation-link-visibility: visible;
        --navigation-nav-display: block;
        opacity: 1;
        transform: scale(1);
        visibility: visible;
        transition: opacity 0.8s, transform 0.78s cubic-bezier(0.5, 0, 0, 1);
    }

    &__footer {
        padding-bottom: rem-calc(40px);

        @include media('>=desktop') {
            display: flex;
            justify-content: space-between;
            padding-block: rem-calc(47px);
            position: relative;
        }

        &::before {
            bottom: 0;
            content: "";
            display: block;
            left: 50%;
            position: absolute;
            top: 0;
            transform: translateX(-50%);
            width: 100dvw;
            z-index: map-get($z-index, "navigation-layer-footer-before");

            @include media('>=desktop') {
                background: $midnight;
            }
        }
    }
}
