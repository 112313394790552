.checklist {
    li {
        padding-left: em(32px, 20px);

        &::before {
            background-color: transparent;
            background-image: url("./../../images/check-white.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: em(14px, 20px) auto;
            border-radius: 0;
            height: em(17px, 20px);
            left: 0;
            top: em(5px, 20px);
            width: em(17px, 20px);
        }
    }
}
