.teaser {
    aspect-ratio: var(--teaser-aspect-ratio, $teaser-image-aspect-ratio);
    box-shadow: var(--teaser-box-shadow);
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    max-width: var(--teaser-max-width, #{rem-calc($teaser-image-max-width)});
    overflow: hidden;
    position: relative;
    transition: box-shadow 0.25s ease-in;
    width: var(--teaser-width, $teaser-image-width-xs);

    @include media('>=tablet') {
        --teaser-width: auto;
    }

    &__btn {
        pointer-events: auto;

        &,
        &:link,
        &:visited {
            text-decoration: none;
        }

        &::before,
        &::after {
            bottom: 0;
            display: block;
            content: '';
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: opacity 0.3s ease-out;
            z-index: map-get($z-index, "teaser-btn");
        }

        &::before {
            background: $gradient-teaser-image;
            opacity: var(--teaser-image-gradient-opacity, 1);
        }

        &::after {
            background: $gradient-teaser-hover;
            opacity: var(--teaser-hover-gradient-opacity, 0);
        }

        &:hover {
            --teaser-image-gradient-opacity: 0;
            --teaser-hover-gradient-opacity: 1;

            @include media('>=tablet-wide') {
                .btn-primary {
                    left: 0;
                    padding-inline: em(51.5px, $font-size-btn);
                }
            }
        }
    }

    &__image {
        grid-area: 1 / 1 / 3 / 2;

        img {
            display: block;
            height: auto;
            width: 100%;
        }
    }

    &__top {
        text-align: center;
        padding-top: 0;

        &::after {
            background-color: currentColor;
            border-radius: 100%;
            content: '';
            display: block;
            height: rem-calc(3px);
            margin: rem-calc(5px) auto 0;
            width: rem-calc(120px);
        }
    }

    &__content {
        display: grid;
        grid-area: 1 / 1 / 3 / 2;
        grid-auto-rows: auto 1fr;
        padding: rem-calc(10px) Clamp(#{rem-calc(22px)}, #{vw(68px, 1440px)}, #{rem-calc(68px)}) Clamp(#{rem-calc(35px)}, #{vw(46px, 1440px)}, #{rem-calc(46px)});
        pointer-events: none;
        z-index: map-get($z-index, "teaser-content");
    }

    &__bottom {
        margin-top: auto;
    }

    &.toggle--is-pressed {
        --teaser-box-shadow: #{0 0 rem-calc(40px) 0 rgba(white, 0.75)};
    }

    &--large {
        --teaser-aspect-ratio: #{$teaser-large-image-aspect-ratio};
        --teaser-max-width: #{rem-calc($teaser-large-image-max-width)};
        --teaser-width: auto;
    }
}
