@mixin box-shadow-focus($focus-type) {
    box-shadow: var(--error-box-shadow, 0 0 0 0 transparent);
    transition: var(--transition-override, #{box-shadow 0.25s ease-in});

    @if $focus-type == "focus-visible" or $focus-type == "" {
        &:focus-visible {
            box-shadow: var(--error-box-shadow, 0 0 0 0 transparent), $btn-focus-shadow;
            outline: 0;
        }
    } @else if $focus-type == "focus" {
        &:focus {
            box-shadow: var(--error-box-shadow, 0 0 0 0 transparent), $btn-focus-shadow;
            outline: 0;
        }
    }
}
