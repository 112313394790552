.amazon-pay-button {
	border-radius: $btn-border-radius;
	margin-bottom: rem-calc(6px);
	overflow: hidden;
}

.amazon-pay-button-wrapper {
	@include font-small;
	text-align: center;
	text-transform: uppercase;
}