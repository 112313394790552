@media print {
    body {
        background: white !important;

        * {
            background: white !important;
            box-shadow: none !important;
            color: black !important;

            &::before,
            &::after {
                display: none !important;
            }
        }
    }

    header,
    footer,
    video,
    .steplist {
        display: none !important;
    }

    .checkout-confirmation,
    .checkout-total {
        .btn-primary {
            display: none !important;
        }
    }
}
